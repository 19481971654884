import React, { useMemo } from 'react'
import { Alert, Box, TextField, Button, useTokens, AlertTitle, Typography, Chip } from '@barracuda-internal/bds-core-v2'
import { CheckCircle } from '@barracuda-internal/bds-core-v2/dist/Icons/Core'
import { useFormatMessage } from 'lib/localization'
import { onEnter } from 'lib/inputEventHandlers'

import { useVerifyDomainLogic } from 'components/pages/setupFlow/views/verifyDomainComponents/verifyDomain/useVerifyDomainLogic'
import SetupFlowStepsContentContainer from '../../../layout/SetupFlowStepsContentContainer'
import ContentTitleComponent from '../../../components/ContentTitleComponent'
import ContentSidePaddingComponent from '../../layout/ContentSidePaddingComponent'
import ErrorComponent from '../../../components/ErrorComponent'

const BASE_I18N_KEY = 'ess_wizard.verify_domain'

const VerifyDomain = () => {
  const tokens = useTokens()
  const formatMessage = useFormatMessage(BASE_I18N_KEY)

  const [state, eventHandlers] = useVerifyDomainLogic()

  let chipBackgroundColor = tokens.chipColorBgStaticNeutralInfo
  let chipBorderColor = tokens.chipColorBorderStaticNeutralInfo

  if (state.isVerifiedServer) {
    chipBackgroundColor = tokens.chipColorBgStaticNeutralSuccess
    chipBorderColor = tokens.chipColorBorderStaticNeutralSuccess
  } else if (state.isMissedServerVerify || state.isVerifyEmailServerFailed || state.isEmailServerConfigError) {
    chipBackgroundColor = tokens.chipColorBgStaticNeutralError
    chipBorderColor = tokens.chipColorBorderStaticNeutralError
  }

  return useMemo(
    () => (
      <SetupFlowStepsContentContainer>
        <ContentTitleComponent title={formatMessage('title')} subtitle={formatMessage('subtitle')} />
        <ErrorComponent />
        {state.isMissedServerDetection && (
          <ContentSidePaddingComponent>
            <Alert bdsBoxShadowType="inline" bdsType="neutralError">
              {formatMessage('missed_email_server')}
            </Alert>
          </ContentSidePaddingComponent>
        )}
        <Box
          sx={{
            display: 'flex',
            padding: tokens.aliasesSpaceContainerDefault,
            gap: tokens.aliasesSpaceContainerDefault
          }}
        >
          <TextField
            data-testid="email-input"
            autoFocus
            error={state.isEmailInputError}
            size="small"
            disabled={state.isDetectEmailServerInProgress}
            label={formatMessage('email_address')}
            value={state.emailAddress}
            onChange={eventHandlers.onChangeEmailAddress}
            onKeyDown={onEnter(eventHandlers.onDetectEmailServer)}
            helperText={state.isEmailInputError && formatMessage('invalid_email_address')}
            sx={{ flexGrow: '1' }}
          />
          <Button
            bdsType="interactiveSubtle"
            data-testid="detect-email-server"
            isLoading={state.isDetectEmailServerInProgress}
            onClick={eventHandlers.onDetectEmailServer}
          >
            {formatMessage('detect_email_server')}
          </Button>
        </Box>

        {state.emailServerConfig && !state.defaultEmailServerConfig?.server?.length && !state.isVerifiedServer && (
          <ContentSidePaddingComponent>
            <Alert data-testid="unable-to-detect-email-server" bdsBoxShadowType="inline" bdsType="neutralError">
              <AlertTitle>{formatMessage('unable_to_detect_email_server_title')}</AlertTitle>
              {`- ${formatMessage('unable_to_detect_email_server_subtitle_1')}`}
              <br />
              {`- ${formatMessage('unable_to_detect_email_server_subtitle_2')}`}
              <br />
              {`- ${formatMessage('unable_to_detect_email_server_subtitle_3')}`}
            </Alert>
          </ContentSidePaddingComponent>
        )}

        {state.isMissedServerVerify && (
          <ContentSidePaddingComponent>
            <Alert data-testid="verify-server-error" bdsBoxShadowType="inline" bdsType="neutralError">
              {formatMessage('verify_server_error')}
            </Alert>
          </ContentSidePaddingComponent>
        )}

        <ContentSidePaddingComponent>
          <Typography
            sx={{
              fontFamily: tokens.aliasesFontFamilyDefault,
              fontSize: tokens.aliasesFontSizeH4,
              fontWeight: tokens.aliasesFontWeightNeutral,
              color: tokens.aliasesColorTextStaticEmphasis,
              lineHeight: tokens.aliasesLineHeightH4
            }}
          >
            {formatMessage('mail_server')}
          </Typography>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              padding: tokens.aliasesSpaceContainerDefault,
              backgroundColor: tokens.aliasesColorBgStaticContainerNeutral,
              gap: tokens.aliasesSpaceContainerSpacious
            }}
          >
            <Box
              sx={{ display: 'flex', flexGrow: '1', alignItems: 'center', gap: tokens.aliasesSpaceContainerDefault }}
            >
              <Box sx={{ flexGrow: '1' }}>
                <Typography
                  sx={{
                    fontFamily: tokens.aliasesFontFamilyDefault,
                    fontSize: tokens.aliasesFontSizeDefault,
                    fontWeight: tokens.aliasesFontWeightDefault,
                    color: tokens.aliasesColorTextStaticEmphasis,
                    lineHeight: tokens.aliasesLineHeightDefault
                  }}
                >
                  {`${formatMessage('domain')}: `}
                </Typography>
                {!state.isEditEmailServer && (
                  <Typography
                    sx={{
                      fontFamily: tokens.aliasesFontFamilyDefault,
                      fontSize: tokens.aliasesFontSizeCaption,
                      fontWeight: tokens.aliasesFontWeightNeutral,
                      color: tokens.aliasesColorTextStaticSubtle,
                      lineHeight: tokens.aliasesLineHeightCaption
                    }}
                  >
                    {state.emailServerConfig?.server}
                  </Typography>
                )}
                {state.isEditEmailServer && (
                  <TextField
                    data-testid="edit-email-server-name"
                    autoFocus
                    fullWidth
                    error={state.isEmailServerConfigError && !state.emailServerConfig?.server.length}
                    size="small"
                    value={state.emailServerConfig?.server}
                    onChange={eventHandlers.onChangeEmailServer}
                    onKeyDown={onEnter(eventHandlers.onFinishEdit)}
                    helperText={
                      state.isEmailServerConfigError &&
                      ((!state.emailServerConfig?.server.length && formatMessage('mail_server_name_error')) ||
                        (!String(state.emailServerConfig?.port).length && ' '))
                    }
                    sx={{ width: 142 }}
                  />
                )}
              </Box>
              <Box sx={{ flexGrow: '1' }}>
                <Typography
                  sx={{
                    fontFamily: tokens.aliasesFontFamilyDefault,
                    fontSize: tokens.aliasesFontSizeDefault,
                    fontWeight: tokens.aliasesFontWeightDefault,
                    color: tokens.aliasesColorTextStaticEmphasis,
                    lineHeight: tokens.aliasesLineHeightDefault
                  }}
                >
                  {`${formatMessage('port')}: `}
                </Typography>
                {!state.isEditEmailServer && (
                  <Typography
                    sx={{
                      fontFamily: tokens.aliasesFontFamilyDefault,
                      fontSize: tokens.aliasesFontSizeCaption,
                      fontWeight: tokens.aliasesFontWeightNeutral,
                      color: tokens.aliasesColorTextStaticSubtle,
                      lineHeight: tokens.aliasesLineHeightCaption
                    }}
                  >
                    {state.emailServerConfig?.port}
                  </Typography>
                )}
                {state.isEditEmailServer && (
                  <TextField
                    data-testid="edit-email-server-port"
                    fullWidth
                    size="small"
                    error={state.isEmailServerConfigError && !String(state.emailServerConfig?.port).length}
                    value={String(state.emailServerConfig?.port)}
                    onChange={eventHandlers.onChangeServerPort}
                    onKeyDown={onEnter(eventHandlers.onFinishEdit)}
                    helperText={
                      state.isEmailServerConfigError &&
                      ((!String(state.emailServerConfig?.port).length && formatMessage('mail_server_port_error')) ||
                        (!state.emailServerConfig?.server.length && ' '))
                    }
                    sx={{ width: 142 }}
                  />
                )}
              </Box>
              <Chip
                bdsSize="medium"
                bdsType="staticNeutral"
                startIcon={
                  state.isVerifiedServer ? (
                    <CheckCircle sx={{ width: 12, height: 12, marginLeft: '0 !important' }} />
                  ) : undefined
                }
                label={state.isVerifiedServer ? formatMessage('verified') : formatMessage('unverified')}
                sx={{
                  gap: tokens.chipSizeMediumSpaceBetween,
                  backgroundColor: chipBackgroundColor,
                  borderColor: chipBorderColor,
                  fontSize: '10px'
                }}
              />
            </Box>
            <Box sx={{ display: 'flex', gap: tokens.aliasesSpaceContainerDefault }}>
              <Button
                data-testid="verify-server-button"
                bdsType="interactiveSubtle"
                isLoading={state.isVerifyEmailServerInProgress}
                onClick={eventHandlers.onEdit}
                disableRipple
                sx={{ minWidth: 55, maxWidth: 55 }}
              >
                {formatMessage('edit')}
              </Button>
              <Button
                data-testid="verify-server-button"
                bdsType="interactiveNeutral"
                isLoading={state.isVerifyEmailServerInProgress}
                onClick={eventHandlers.onVerifyServer}
              >
                {formatMessage('verify_server')}
              </Button>
            </Box>
          </Box>
        </ContentSidePaddingComponent>

        <ContentSidePaddingComponent>
          <Alert bdsType="neutralInfo" bdsBoxShadowType="inline">
            {formatMessage('server_update_info')}
          </Alert>
        </ContentSidePaddingComponent>
      </SetupFlowStepsContentContainer>
    ),
    [tokens, formatMessage, state, eventHandlers, chipBackgroundColor, chipBorderColor]
  )
}

export default VerifyDomain
