import React, { useMemo } from 'react'
import { useFormatMessage } from 'lib/localization'
import SetupFlowStepsContentContainer from '../../layout/SetupFlowStepsContentContainer'
import ContentTitleComponent from '../../components/ContentTitleComponent'
import ErrorComponent from '../../components/ErrorComponent'

const BASE_I18N_KEY = 'ess_wizard.introduction'

const Introduction: React.FC = () => {
  const formatMessage = useFormatMessage(BASE_I18N_KEY)

  return useMemo(
    () => (
      <SetupFlowStepsContentContainer>
        <ContentTitleComponent title={formatMessage('title')} subtitle={formatMessage('subtitle')} />
        <ErrorComponent />
        <img alt="" src="/images/step-1-visual.jpg" />
      </SetupFlowStepsContentContainer>
    ),
    [formatMessage]
  )
}

export default Introduction
