import React, { useMemo } from 'react'
import { useAppSelector } from 'redux/toolkit/hooks'
import { DeploymentSetupOptions } from '../../types/egdWizardTypes'
import MxEmailFlow from './mxEmailFlow/EmailFlow'
import InlineEmailFlow from './inlineEmailFlow/InlineEmailFlow'

const EmailFlowSelector: React.FC = () => {
  const { deploymentSetup } = useAppSelector(_store => ({
    deploymentSetup: _store.emailServer.deploymentSetup
  }))

  return useMemo(() => {
    if (deploymentSetup === DeploymentSetupOptions.mxRecord) {
      return <MxEmailFlow />
    }
    if (deploymentSetup === DeploymentSetupOptions.inline) {
      return <InlineEmailFlow />
    }
    return null
  }, [deploymentSetup])
}

export default EmailFlowSelector
