import React, { useMemo } from 'react'

import { Button, Grid, Typography } from '@barracuda-internal/bds-core'
import { useFormatMessage } from 'lib/localization'
import { AvailableSettings, SettingValue } from 'types/Settings'
import { useSettingsStateLogic } from 'components/libs/useSettingsState'

import DomainTable from 'components/libs/domainTable/DomainTable'
import outboundSettingsStyles from 'components/pages/outboundSettings/outboundSettingsStyles'
import OutboundDlp from 'components/pages/support/help/outboundDlp/OutboundDlp'
import { GROUP_TITLE, TOPICS } from 'components/pages/support/config'
import SettingsRowRadio from 'components/libs/settings/SettingsRowRadio'
import SettingsRowHelper from 'components/libs/settings/SettingsRowHelper'
import { PageTitle, FormSpacer } from 'components/libs/layout/pages/pageLayout'
import { SettingsPageContainer } from 'components/libs/layout/pages/SettingsPageContainer'
import { useEncryptionLogic } from './useEncryptionLogic'

const BASE_I18N_KEY = 'ess.outbound_settings.encryption'
const BASE_INBOUND_I18N_KEY = 'ess.inbound_settings'

const Encryption: React.FC = () => {
  const classes = outboundSettingsStyles()
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const formatInboundMessage = useFormatMessage(BASE_INBOUND_I18N_KEY)

  const [state, eventHandlers] = useEncryptionLogic()
  const [UseGetSettingsStateLogic] = useSettingsStateLogic()

  return useMemo(
    () => (
      <SettingsPageContainer
        key={state.key}
        isLoading={UseGetSettingsStateLogic.isLoading}
        isFailedToLoad={UseGetSettingsStateLogic.isFailedToLoad}
        dirtyCheck
        isDirtyForm={state.hasPageChanges}
        isPageScrolled
      >
        <PageTitle
          title={formatMessage('title')}
          onOpenSupport={eventHandlers.helpConfig.onHelpClick}
          onCancel={eventHandlers.onCancelConfirm}
          onSubmit={eventHandlers.onSave}
          submitInProgress={state.isUpdateInProgress}
          isDisabledSubmit={!state.hasPageChanges}
          help={{
            content: OutboundDlp,
            onClose: eventHandlers.helpConfig.onCloseHelp,
            title: TOPICS.dlpEncryption,
            group: GROUP_TITLE.outboundSettings,
            isHelpOpen: eventHandlers.helpConfig.isOpen
          }}
        />
        <FormSpacer />
        <Grid container direction="row">
          <SettingsRowRadio
            name={AvailableSettings.OUTBOUND_TLS_ALL}
            onChange={eventHandlers.onFormChange}
            options={[
              { label: formatMessage('all_messages'), value: SettingValue.ENABLED },
              { label: formatMessage('selected_domains'), value: SettingValue.DISABLED }
            ]}
            value={state.tlsAll}
            labelColumnSize={1}
            radioDirection="column"
          />

          {state.tlsChanged && (
            <SettingsRowHelper textVariant="h5" labelColumnSize={1} text={formatMessage('save_changes_helper')} />
          )}

          <Grid item xs={12} className={classes.topSpacing}>
            <Grid container direction="row" className={classes.flexWrapper}>
              <Grid>
                <Typography variant="body1">
                  {state.tlsAll === SettingValue.ENABLED
                    ? formatMessage('table_description_all', {
                        b: (txt: any) => <b>{txt}</b>
                      })
                    : formatMessage('table_description_select')}
                </Typography>
                <Typography variant="body1">{formatMessage('table_sub_description')}</Typography>
              </Grid>
              <Button
                disabled={state.tlsChanged}
                className={classes.bulkEdit}
                onClick={eventHandlers.onBulkEdit}
                variant="contained"
                size="small"
                data-testid="bulk-button"
              >
                {formatInboundMessage('bulk_edit')}
              </Button>
            </Grid>

            <DomainTable
              data={state.data}
              onAddItem={eventHandlers.onAddItem}
              onRemoveItem={eventHandlers.onRemoveItem}
              data-testid="domain-table"
              delegateDirtyFormState={eventHandlers.setIsEncryptionTableDirty}
            />
          </Grid>
        </Grid>
      </SettingsPageContainer>
    ),
    [UseGetSettingsStateLogic, state, formatMessage, eventHandlers, classes, formatInboundMessage]
  )
}

export default Encryption
