import { Box, CircularProgress, Typography, useTokens } from '@barracuda-internal/bds-core-v2'
import React, { useMemo } from 'react'

const LoadingOverlayComponent: React.FC<{ description?: string }> = ({ description }) => {
  const tokens = useTokens()

  return useMemo(
    () => (
      <Box
        sx={{
          position: 'fixed',
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          backgroundColor: 'rgba(255,255,255, 0.7)',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          zIndex: 5000,
          padding: tokens.aliasesSpaceContainerSpacious
        }}
      >
        <CircularProgress data-testid="loading-state" />
        {description && (
          <Typography
            sx={{
              fontFamily: tokens.aliasesFontFamilyDefault,
              fontSize: tokens.aliasesFontSizeH4,
              fontWeight: tokens.aliasesFontWeightEmphasis,
              color: tokens.aliasesColorTextStaticEmphasis,
              lineHeight: tokens.aliasesLineHeightDefault,
              padding: tokens.aliasesSpaceContainerDefault
            }}
          >
            {description}
          </Typography>
        )}
      </Box>
    ),
    [tokens, description]
  )
}

export default LoadingOverlayComponent
