import React, { useMemo } from 'react'
import { useAppSelector } from 'redux/toolkit/hooks'
import VerifyMsDomain from './verifyMsDomain/VerifyMsDomain'
import VerifyDomain from './verifyDomain/VerifyDomain'

const VerifyDomainSelector: React.FC = () => {
  const { verifiedDomains } = useAppSelector(_store => ({
    verifiedDomains: _store.emailServer.emailServerSettings?.microsoftVerifiedDomains
  }))

  return useMemo(() => {
    if (verifiedDomains) {
      return <VerifyMsDomain />
    }
    if (!verifiedDomains) {
      return <VerifyDomain />
    }
    return null
  }, [verifiedDomains])
}

export default VerifyDomainSelector
