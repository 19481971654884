import React, { PropsWithChildren, useMemo } from 'react'

import { Grid } from '@barracuda-internal/bds-core'
import WarningDialog from 'components/libs/dialog/warningDialog/WarningDialog'
import { useFormatMessage } from 'lib/localization'
import { useBlockTransition } from 'lib/useBlockTransition'

import styles from 'components/libs/layout/pages/pagesLayoutStyles'
import ContentLoader from 'components/libs/contentLoader/ContentLoader'
import DomainConflictDialog from 'components/libs/domainConflictDialog/DomainConflictDialog'
import { useSettingsStateLogic } from '../../useSettingsState'

const BASE_I18N_KEY = 'ess.layout'

export type SettingsPageContainerProps = {
  isLoading?: boolean
  isFailedToLoad?: boolean
  dirtyCheck?: boolean
  isDirtyForm?: boolean
  fullPage?: boolean
  isPageScrolled?: boolean
}

export const SettingsPageContainer = ({
  children,
  dirtyCheck = false,
  isDirtyForm = false,
  fullPage = false,
  isPageScrolled = false
}: PropsWithChildren<SettingsPageContainerProps>) => {
  const classes = styles()
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const [transitionCb, resetTransitionCb] = useBlockTransition({ dirtyCheck, isDirtyForm })
  const [UseSettingsStateLogic] = useSettingsStateLogic()

  return useMemo(
    () => (
      <ContentLoader
        fullPage={fullPage}
        isPageScrolled={isPageScrolled}
        isLoading={UseSettingsStateLogic.isLoading}
        isFailed={UseSettingsStateLogic.isFailedToLoad}
      >
        <Grid className={classes.pageContainer} alignItems="center" container direction="column">
          {children}
        </Grid>
        {!!transitionCb && (
          <WarningDialog
            title={formatMessage('unsaved_dialog.title')}
            description={formatMessage('unsaved_dialog.text')}
            onCancel={resetTransitionCb}
            onConfirm={transitionCb}
          />
        )}
        <DomainConflictDialog />
      </ContentLoader>
    ),
    [
      UseSettingsStateLogic,
      fullPage,
      isPageScrolled,
      classes.pageContainer,
      children,
      transitionCb,
      formatMessage,
      resetTransitionCb
    ]
  )
}
