import React, { useEffect, useMemo, useRef, useState } from 'react'

import { CircularProgress, Grid } from '@barracuda-internal/bds-core'

import ContentLoaderMessage from 'components/libs/contentLoader/ContentLoaderMessage'
import styles from 'components/libs/contentLoader/contentLoaderStyles'

export interface ContentLoaderProps {
  isLoading: boolean
  isFailed: boolean
  errorMessage?: string
  fullPage?: boolean
  isPageScrolled?: boolean
  children?: any
}

function ContentLoader({
  isLoading,
  isFailed,
  errorMessage,
  children = null,
  fullPage = false,
  isPageScrolled = false
}: ContentLoaderProps) {
  const classes = styles()
  const [contentHeight, setContentHeight] = useState<number>(0)
  const contentRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (contentRef.current && !fullPage) {
      setContentHeight(contentRef.current?.clientHeight)
    }
  }, [fullPage])

  return useMemo(
    () => (
      <div className={fullPage ? classes.fullPageWrapper : classes.wrapper}>
        <Grid
          data-testid="rendered-content"
          ref={contentRef}
          container
          className={fullPage ? classes.fullPageWrapper : classes.wrapper}
        >
          {children}
        </Grid>
        {(isLoading || isFailed) && (
          <div
            data-testid="content-statuses-section"
            className={fullPage || isPageScrolled ? classes.fullSizeStaticContainer : classes.fullSizeContainer}
            style={{ height: fullPage || isPageScrolled ? 'auto' : contentHeight, zIndex: 1299 }}
          >
            <Grid
              className={
                fullPage || isPageScrolled ? classes.fullSizeOpacityStaticContainer : classes.fullSizeOpacityContainer
              }
            />
            <Grid container justifyContent="center" alignContent="center" direction="column">
              {isLoading && <CircularProgress data-testid="loading-state" className={classes.loadingIcon} />}
              {isFailed && <ContentLoaderMessage message={errorMessage} />}
            </Grid>
          </div>
        )}
      </div>
    ),
    [classes, isLoading, isFailed, errorMessage, children, contentHeight, fullPage, isPageScrolled]
  )
}

export default ContentLoader
