import React, { useMemo } from 'react'

import { Typography, Box, useTokens, Alert, Button } from '@barracuda-internal/bds-core-v2'
import { useFormatMessage } from 'lib/localization'
import SetupFlowStepsContentContainer from '../../../layout/SetupFlowStepsContentContainer'
import ContentTitleComponent from '../../../components/ContentTitleComponent'
import ErrorComponent from '../../../components/ErrorComponent'
import { useInlineEmailFlowLogic } from './useInlineEmailFlowLogic'

const BASE_I18N_KEY = 'ess_wizard.email_flow'

const InlineEmailFlow = () => {
  const tokens = useTokens()
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const [state, eventHandlers] = useInlineEmailFlowLogic()

  return useMemo(
    () => (
      <Box sx={{ height: '100%' }}>
        <SetupFlowStepsContentContainer>
          <ContentTitleComponent title={formatMessage('title')} subtitle={formatMessage('inline_subtitle')} />
          <ErrorComponent />
          {state.error && (
            <Alert bdsBoxShadowType="inline" bdsType="neutralError">
              {formatMessage('inline_deployment_error', {
                a: (txt: any): any => (
                  <Button
                    bdsType="interactiveSubtle"
                    sx={{ ':hover': { background: 'transparent' }, padding: 0, height: 'auto' }}
                    onClick={eventHandlers.onErrorClick}
                  >
                    {txt}
                  </Button>
                )
              })}
            </Alert>
          )}
          <Typography
            sx={{
              fontFamily: tokens.aliasesFontFamilyDefault,
              fontSize: tokens.aliasesFontSizeDefault,
              fontWeight: tokens.aliasesFontWeightDefault,
              color: tokens.aliasesColorTextStaticEmphasis,
              lineHeight: tokens.aliasesLineHeightDefault,
              padding: tokens.aliasesSpaceContainerDefault
            }}
          >
            {formatMessage('inline_content')}
          </Typography>
          <Box sx={{ width: 390, padding: tokens.aliasesSpaceContainerDefault }}>
            <img alt="" src="/images/inline_setup.svg" />
          </Box>
        </SetupFlowStepsContentContainer>
      </Box>
    ),
    [tokens, formatMessage, state, eventHandlers]
  )
}

export default InlineEmailFlow
