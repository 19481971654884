import React, { useMemo } from 'react'

import { Button, Grid } from '@barracuda-internal/bds-core'
import inboundSettingsStyles from 'components/pages/inboundSettings/inboundSettingsStyles'
import { useFormatMessage } from 'lib/localization'
import InboundCustomRbls from 'components/pages/support/help/inboundCustomRbls/InboundCustomRbls'
import { GROUP_TITLE, TOPICS } from 'components/pages/support/config'
import { useCustomRblsLogic } from 'components/pages/inboundSettings/customRbls/useCustomRblsLogic'
import { PageTitle, FormSpacer } from 'components/libs/layout/pages/pageLayout'
import { SettingsPageContainer } from 'components/libs/layout/pages/SettingsPageContainer'
import CustomRblsTable from './CustomRblsTable'

const BASE_INBOUND_I18N_KEY = 'ess.inbound_settings'
const BASE_I18N_KEY = 'ess.inbound_settings.custom_rbls'

const CustomRbls: React.FC = () => {
  const inboundClasses = inboundSettingsStyles()
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const formatInboundMessage = useFormatMessage(BASE_INBOUND_I18N_KEY)
  const [state, eventHandlers] = useCustomRblsLogic()

  return useMemo(
    () => (
      <SettingsPageContainer dirtyCheck isDirtyForm={state.hasPageChanges} isPageScrolled>
        <PageTitle
          title={formatMessage('title')}
          onOpenSupport={eventHandlers.helpConfig.onHelpClick}
          help={{
            content: InboundCustomRbls,
            onClose: eventHandlers.helpConfig.onCloseHelp,
            title: TOPICS.customRbls,
            group: GROUP_TITLE.inboundSettings,
            isHelpOpen: eventHandlers.helpConfig.isOpen
          }}
        />
        <FormSpacer />
        <Grid container direction="row">
          <Button
            className={inboundClasses.bulkEdit}
            onClick={eventHandlers.onBlukEdit}
            variant="contained"
            size="small"
            data-testid="bulk-button"
          >
            {formatInboundMessage('bulk_edit')}
          </Button>
          <CustomRblsTable
            data={state.data}
            onAddItem={eventHandlers.onAdd}
            onRemoveItem={eventHandlers.onRemove}
            delegateDirtyFormState={eventHandlers.setIsCustomRblsTableDirty}
          />
        </Grid>
        <FormSpacer />
      </SettingsPageContainer>
    ),
    [inboundClasses, formatMessage, formatInboundMessage, state, eventHandlers]
  )
}

export default CustomRbls
