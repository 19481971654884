const API_VERSION = 'v1'

declare global {
  interface Window {
    __egd__: {
      getMissedTranslates?: () => void
      forceEnabledUserRoleValidation?: () => void
    }
  }
}

export enum AppNames {
  enduser = 'webui',
  admin = 'admin',
  wizard = 'wizard',
  cudaspt = 'cudaspt'
}

const isDev = process.env.NODE_ENV === 'development'
const isGT3 = window?.location?.host?.includes('gt3.ess')
const isStaging = window?.location?.host?.includes('staging1.ess')
const isProd = process.env.NODE_ENV === 'production' && !isGT3 && !isStaging
const auth0EnabledApps = [AppNames.enduser]

const ENVIRONMENT = {
  IS_TEST: process.env.NODE_ENV === 'test',
  IS_DEV: isDev,
  IS_GT3: isGT3,
  IS_STAGING: isStaging,
  IS_PRODUCTION: isProd
}

const is_supported_https_protocol =
  window?.location?.protocol?.startsWith('https') || (ENVIRONMENT.IS_DEV && process.env.AUTH0_SIMULATED_HTTPS_ENABLED)

const API_PATHNAME = process.env.REACT_APP_GATEWAY_URL
  ? new URL(process.env.REACT_APP_GATEWAY_URL).pathname.slice(1)
  : ''

export interface App {
  NAME: AppNames
  IS_ENDUSER: boolean
  IS_ADMIN: boolean
  IS_WIZARD: boolean
  IS_CUDASPT: boolean
}

// TODO: Remove when Cudaspt app is released
const isCudasptAppEnabled = [ENVIRONMENT.IS_DEV].some(env => env)

const APP = (
  [
    [AppNames.wizard, 'IS_WIZARD'],
    [AppNames.admin, 'IS_ADMIN'],
    [AppNames.cudaspt, isCudasptAppEnabled ? 'IS_CUDASPT' : undefined]
  ] as any[]
).reduce(
  (all, appNameConfig) => {
    const [appName, keyName] = appNameConfig
    if (keyName && appName) {
      const isTheCurrentApp = window?.location?.pathname?.includes(appName) || false

      return {
        ...all,
        [keyName]: isTheCurrentApp,
        ...(isTheCurrentApp && { NAME: appName, IS_ENDUSER: false })
      }
    }

    return all
  },
  { NAME: AppNames.enduser, IS_ENDUSER: true, IS_ADMIN: false, IS_WIZARD: false, IS_CUDASPT: false } as App
)

const APP_CONFIG = {
  API_VERSION,
  NAME: 'ESS',
  PRODUCT_PREFIX: 'EGD',
  DEBUG_MODE: !isProd || process.env.DEBUG_MODE,
  LANGUAGE: 'en',
  APP_NAMES: {
    ENDUSER: AppNames.enduser,
    ADMIN: AppNames.admin,
    WIZARD: AppNames.wizard,
    CUDASPT: AppNames.cudaspt
  },
  APP,
  BASE_PATHS: {
    [AppNames.enduser]: 'webui',
    [AppNames.admin]: 'admin',
    [AppNames.wizard]: 'wizard',
    [AppNames.cudaspt]: 'cudaspt'
  } as { [key: string]: string },
  ENVIRONMENT,
  GATEWAY_URL: process.env.REACT_APP_GATEWAY_URL,
  LEGACY_UI_URL: process.env.REACT_APP_LEGACY_UI_URL,
  AUTH0: {
    domain: process.env.AUTH0_DOMAIN,
    clientId: process.env.AUTH0_CLIENT_ID,
    audience: process.env.AUTH0_AUDIENCE,
    isEnabled:
      is_supported_https_protocol &&
      !!(process.env.AUTH0_DOMAIN && process.env.AUTH0_CLIENT_ID && process.env.AUTH0_AUDIENCE) &&
      auth0EnabledApps.includes(APP.NAME)
  },
  SPLITIO: {
    API_KEY: process.env.SPLITIO_API_KEY
  },
  INTERCOM: {
    APP_ID: process.env.INTERCOM_APP_ID,
    SECRET: process.env.INTERCOM_SECRET,
    IS_ENABLED: process.env.INTERCOM_IS_ENABLED === 'true' || false
  },
  MIXPANEL: {
    TOKEN: process.env.MIXPANEL_TOKEN,
    IS_ENABLED: process.env.MIXPANEL_IS_ENABLED === 'true' || false
  },
  NEW_RELIC: {
    TRACKING_URL: process.env.NEW_RELIC_TRACKING_URL || 'bam.nr-data.net',
    ACCOUNT_ID: process.env.NEW_RELIC_ACCOUNT_ID || '978421',
    TRUST_KEY: process.env.NEW_RELIC_TRUST_KEY || '189019',
    LICENSE_KEY: process.env.NEW_RELIC_LICENSE_KEY || '9583f6425f',
    AGENT_ID: process.env.NEW_RELIC_AGENT_ID || process.env.NEW_RELIC_APPLICATION_ID,
    APPLICATION_ID: process.env.NEW_RELIC_APPLICATION_ID,
    IS_ENABLED: !!process.env.NEW_RELIC_APPLICATION_ID
  },
  MUI_X_LICENSE_KEY: process.env.MUI_X_LICENSE_KEY,
  DOMAIN: process.env.REACT_APP_DOMAIN,
  ONETRUST_ID: process.env.REACT_APP_ONETRUST_ID,
  FEATURES: {
    DARK_MODE: process.env.REACT_APP_DARK_MODE
  },
  LINKS: {
    PRIVACY_POLICY: 'https://www.barracuda.com/support/security',
    CAMPUS_SEARCHING_FOR_EMAILS: 'https://campus.barracuda.com/doc/91128973/',
    BARRACUDA_ESSENTIALS: 'https://www.barracuda.com/products/essentials',
    BARRACUDA_CONTENT_SHIELD: 'https://www.barracuda.com/fir-bcs',
    BARRACUDA_PHISHLINE_VIDEO: 'https://campus.barracuda.com/to/12US',
    BARRACUDA_SETUP_MX_RECORDS: 'https://campus.barracuda.com/doc/98213466/',
    BARRACUDA_ESS_LOG: {
      NON_US: 'https://ess.REGION.barracudanetworks.com/log',
      US: 'https://ess.barracudanetworks.com/log'
    },
    SIDEBAR_URL: 'https://campus.barracuda.com/doc/96023030/',
    FORENSICS_HOSTNAME: process.env.REACT_APP_FORENSICS_HOSTNAME || 'forensics.barracudanetworks.com',
    SAVE_APPLY_ACCOUNT_CONFIG_CAMPUS_LINK: 'https://campus.barracuda.com/doc/78155230/'
  },
  DATETIME: {
    DEFAULT_DATE_FORMAT: 'MMM dd, yyyy',
    DEFAULT_DATE_WITH_TIME_FORMAT: 'MMM dd, yyyy h:mm a',
    DEFAULT_DATE_WITH_AT_TIME_FORMAT: 'MMM dd, yyyy hh:mm a',
    DATE_TIME_PICKER_FORMAT: 'MMM dd, yyyy hh:mm:ss a',
    DATE_PICKER_FORMAT: 'MMM dd, yyyy',
    DEFAULT_ACCEPTED_DATE_FORMAT: 'MM/dd/yyyy hh:mm:ss a',
    REDELIVERY_QUEUE_DATE_FORMAT: 'hh:mm a ZZZZ',
    DASH_SEPARATED_DATE_WITHOUT_TIME: 'yyyy-MM-dd',
    EXPORT_DATE_FORMAT: 'M/d/yy HH:mm',
    TIME_FORMAT: 'h:mm a',
    REPORT_TIME_FORMAT: 'hh:mm:ss a',
    AUDIT_LOG_DATE_WITH_TIME_FORMAT: 'MMM dd, hh:mm a',
    AUDIT_API_DATE_WITH_TIME_FORMAT: 'yyyy-MM-dd HH:mm:ss.uuu',
    ATP_LOG_DATE_WITH_TIME_FORMAT: 'yyyy-MM-dd HH:mm:ss',
    ATP_API_DATE_WITH_TIME_FORMAT: 'yyyy-MM-dd HH:mm:ss.uuu',
    LAST_LDAP_SYNC_FORMAT: 'yyyy-MM-dd HH:mm',
    MONTH_DAY_FORMAT: 'MMM dd',
    AZURE_AUTHORIZATION_DATE_TIME_FORMAT: 'dd/MM/yyyy, HH:mm:ss'
  },
  DEFAULT_ERROR_MESSAGE: 'default',
  BCC_LOGIN_TEST_CREDENTIALS: {
    CLOUD_AT_TOKEN: process.env.BCC_CLOUD_AT_TOKEN,
    PORTAL_ID: process.env.BCC_PORTAL_ID
  },
  API_ERRORS: {
    UNSUPPORTED_MSTORE_SEARCH_WITH_USER_ID: 'Message log search for an email address is only supported for domains'
  },
  QUERY_PARAMS: {
    MIGRATE_TOKEN: 'migrate-token'
  },
  COOKIES: {
    SESSION: 'ess_session_id',
    SIDEBAR_BANNER_HIDDEN: 'isSidebarBannerHidden',
    LANGUAGE_PREFERENCE: 'languagePreference',
    TDF_IP: 'tdf_ip'
  },
  LICENSING_COMPLIANCE_PATH: `/${API_PATHNAME && `${API_PATHNAME}/`}internal/licenses`,
  AZURE_AD: {
    TENANT_STATE_STATIC: '123456789',
    LOOKUP_USER_MAX_TRY_COUNT: 3,
    POLL_USER_STATS_INTERVAL_MS: 5000,
    POLL_USER_STATS_MAX_COUNT: 50
  },
  BCC_PATH: process.env.REACT_APP_BCC_URL,
  LDAP: {
    DEFAULT_AUTH_FILTER: '(|(mail={email_address})(userPrincipalName={email_address})(sAMAccountName={user_name}))',
    AUTH_RETRY_ATTEMPTS: 2,
    AUTH_WAIT_BEFORE_RETRY: 2000
  },
  DOMAIN_MOVE: {
    CNAME_TARGET: 'ess.barracudanetworks.com',
    VERIFY_DOMAIN_TIMEOUT: 310000, // API GW's config.mstore_domain_move_timeout + 10 seconds
    POLL_TRANSFER_STATE_INTERVAL: 10000
  },
  OAUTH2: {
    AZURE_LOGOUT_URL:
      process.env.REACT_APP_AZURE_LOGOUT_URL || 'https://login.microsoftonline.com/common/oauth2/v2.0/logout'
  },
  SIDEBANDMAILER: {
    REFRESH_INTERVAL: Number(process.env.REACT_APP_RDQ_POLL_INTERVAL_MS) || 60000
  },
  INLINE_DEPLOYMENT: {
    POLL_INTERVAL: Number(process.env.REACT_INLINE_DEPLOYMENT_POLL_INTERVAL_MS) || 10000
  }
} as const

export default APP_CONFIG
