import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { trim } from 'lodash'
import routesConfig, { gotoAdminEntryPathFromWizard, gotoEnduserEntryPathFromWizard } from 'lib/routesConfig'
import { useAppDispatch, useAppSelector } from 'redux/toolkit/hooks'
import {
  getInlineDeploymentStatus,
  resetInlineDeployment,
  resetSetupFlowError,
  saveDeploymentMethod,
  saveSetupFlowStep,
  saveSetupFlowVersion,
  setEmailServerSettings,
  setIsEmailInputError,
  setIsMissedServerDetection,
  setIsMissedServerVerify,
  setNewMxRecordsError,
  setOldMxRecordsError,
  setRegion,
  setSelectedRegionError,
  setSetupFlowStep,
  startInlineDeployment
} from 'redux/features/emailServer/emailServerSlice'
import { isMyFeatureOn } from 'lib/splitio'
import { FEATURES } from 'lib/splitioFeatures'
import { isFailed, isPending, isSuccess } from 'redux/toolkit/api'
import { isEmailValid } from 'lib/validation'
import appFeatures from 'config/appFeatures'
import { UserRights, useUserRights } from 'components/pages/onboardWizard/onboardWizardLogicUserRights'
import appConfig from 'config/appConfig'
import { setIntercomUser } from 'lib/monitoring/monitoringService'
import { INLINE_DEPLOYMENT_STATUSES } from 'types/emailServer'
import { msVerifyDomain } from 'redux/features/emailServer/emailServerApiThunks'
import { DeploymentSetupOptions, IPIRAppTypes, SetupFlowSteps } from './types/egdWizardTypes'

const RETURN_URL_SESSION_ID = 'wizardReturnUrl'

export interface StepInfo {
  name: string
  skipped?: boolean
  active?: boolean
}

export interface State {
  currentStep: SetupFlowSteps
  stepInProgress: boolean
  loading: boolean
  nextDisabled: boolean
  steps: StepInfo[]
  inlineDeploymentInProgress: boolean
  completeSetupButton: boolean
}

export interface EventHandlers {
  onExit: () => void
  onNext: () => void
}

export type EmailFlowLogic = [State, EventHandlers]

export const useSetupFlowLogic = (): EmailFlowLogic => {
  const dispatch = useAppDispatch()
  const [params, setParams] = useSearchParams()
  const returnUrl = params.get('returnUrl')

  const { userFeatures, userHasRight } = useUserRights()
  const [isRedirecting, setIsRedirecting] = useState(false)
  const [isSaveAndExit, setIsSaveAndExit] = useState(false)
  const isEGDReactOverviewEnabled = isMyFeatureOn(FEATURES.EGD_React_Overview_Rollout)
  const timer = useRef<number>()
  const [polling, setPolling] = useState(false)

  const {
    user,
    accessToken,
    currentStep,
    emailServerSettings,
    isSaveDeploymentMethodInProgress,
    isSaveSetupFlowStepInProgress,
    isIpirScanStatusInProgress,
    isSetSelectedRegionInProgress,
    selectedRegion,
    emailServerVerified,
    defaultEmailServerConfig,
    emailServerConfig,
    emailAddress,
    verifyDomainState,
    mxRecords,
    oldMxRecords,
    newMxRecordsError,
    oldMxRecordsError,
    isSaveSettingsSuccess,
    isSaveSettingsInProgress,
    isSaveSetupFlowVersionInProgress,
    startInlineDeploymentInProgress,
    startInlineDeploymentSuccess,
    jobName,
    inlineDeploymentStatus,
    deploymentSetup,
    verifiedDomains,
    saveMsDomainInProgress
  } = useAppSelector(_store => ({
    user: _store.auth.accessTokenObject,
    accessToken: _store.auth.accessToken,
    currentStep: _store.emailServer.setupFlowStep,
    emailServerSettings: _store.emailServer.emailServerSettings,
    isSaveDeploymentMethodInProgress: isPending(_store.emailServer.saveDeploymentMethodApiStatus),
    isSaveSetupFlowStepInProgress: isPending(_store.emailServer.saveSetupFlowStepApiStatus),
    isIpirScanStatusInProgress: isPending(_store.emailServer.getIpirScanStatusApiStatus),
    isSetSelectedRegionInProgress: isPending(_store.emailServer.setRegionApiStatus),
    emailServerVerified: _store.emailServer.emailServer?.verified,
    verifyDomainState: _store.emailServer.verifyDomainState,
    defaultEmailServerConfig: _store.emailServer.emailServer,
    emailServerConfig: _store.emailServer.updatedEmailServer,
    emailAddress: _store.emailServer.emailAddress,
    selectedRegion: _store.emailServer.selectedRegion,
    sectionsIsVerifiedStates: _store.emailServer.emailFlowState.validatedSections,
    mxRecords: _store.emailServer.mxRecords,
    oldMxRecords: _store.emailServer.oldMxRecords,
    newMxRecordsError: _store.emailServer.emailFlowState.newRecordsError,
    oldMxRecordsError: _store.emailServer.emailFlowState.oldRecordsError,
    isSaveSettingsSuccess: isSuccess(_store.emailServer.setEmailServerSettingsApiStatus),
    isSaveSettingsInProgress: isPending(_store.emailServer.setEmailServerSettingsApiStatus),
    isSaveSetupFlowVersionInProgress: isPending(_store.emailServer.saveSetupFlowVersionApiStatus),
    startInlineDeploymentInProgress: isPending(_store.emailServer.startInlineDeploymentApiStatus),
    startInlineDeploymentSuccess: isSuccess(_store.emailServer.startInlineDeploymentApiStatus),
    getInlineDeploymentStatusSuccess: isSuccess(_store.emailServer.getInlineDeploymentStatusApiStatus),
    getInlineDeploymentStatusFailed: isFailed(_store.emailServer.getInlineDeploymentStatusApiStatus),
    jobName: _store.emailServer.inlineDeploymentJobName,
    inlineDeploymentStatus: _store.emailServer.inlineDeploymentStatus,
    deploymentSetup: _store.emailServer.deploymentSetup,
    verifiedDomains: _store.emailServer.emailServerSettings?.microsoftVerifiedDomains,
    saveMsDomainInProgress: isPending(_store.emailServer.saveMsDomainMethodApiStatus)
  }))

  const migrateToken = useMemo(() => {
    const migrateTokenSecret = emailServerSettings?.migrateToken
    if (!migrateTokenSecret) {
      return ''
    }

    return `?${appConfig.QUERY_PARAMS.MIGRATE_TOKEN}=${encodeURIComponent(migrateTokenSecret)}`
  }, [emailServerSettings])

  const wizardReturnUrl = useMemo(() => {
    const url = sessionStorage.getItem(RETURN_URL_SESSION_ID)
    if (url) {
      return url
    }
    if (returnUrl) {
      sessionStorage.setItem(RETURN_URL_SESSION_ID, returnUrl)
      return returnUrl
    }

    sessionStorage.setItem(RETURN_URL_SESSION_ID, `${appConfig.BCC_PATH}/trials`)
    return `${appConfig.BCC_PATH}/trials`
  }, [returnUrl])

  // account settings are loaded
  useEffect(() => {
    // 1 - goto endUser app if user is endUser
    if (!appFeatures.DisabledWizardAdminCheck && userHasRight(UserRights.GOTO_ENDUSER_APP)) {
      gotoEnduserEntryPathFromWizard()
      // 2 - goto admin app if the wizard is completed
    } else if (emailServerSettings?.wizardCompleted) {
      gotoAdminEntryPathFromWizard('', isEGDReactOverviewEnabled)
      // 3 - Forward the user to the selected region (if not matched with the current host)
    } else if (
      appFeatures.EnabledRegionChange &&
      emailServerSettings?.url &&
      emailServerSettings.url !== window.location.hostname
    ) {
      window.location.href = `${window.location.origin?.replace(
        window.location.host,
        emailServerSettings?.url || ''
      )}${routesConfig.BCC_LOGIN?.url()}${migrateToken}`
      // default - welcome the user in the wizard
    } else if (currentStep === SetupFlowSteps.introduction && !emailServerSettings?.version) {
      dispatch(saveSetupFlowVersion('v2'))
    }

    return () => {
      clearInterval(timer.current)
    }
  }, [emailServerSettings, currentStep, migrateToken, dispatch, userHasRight, isEGDReactOverviewEnabled, userFeatures])

  // completed
  useEffect(() => {
    if (isSaveSettingsSuccess) {
      if (isSaveAndExit) {
        window.location.href = wizardReturnUrl
        sessionStorage.removeItem(RETURN_URL_SESSION_ID)
        setIsRedirecting(true)
      }
    }
  }, [dispatch, currentStep, isSaveSettingsSuccess, emailServerSettings, isSaveAndExit, wizardReturnUrl])

  // inline setup
  useEffect(() => {
    if (startInlineDeploymentSuccess && jobName) {
      clearInterval(timer.current)
      setPolling(true)
      timer.current = window.setInterval(
        () => dispatch(getInlineDeploymentStatus(jobName)),
        appConfig.INLINE_DEPLOYMENT.POLL_INTERVAL
      )
    }
  }, [dispatch, startInlineDeploymentSuccess, jobName])

  // inline setup status
  useEffect(() => {
    if (
      inlineDeploymentStatus === INLINE_DEPLOYMENT_STATUSES.SUCCEEDED ||
      inlineDeploymentStatus === INLINE_DEPLOYMENT_STATUSES.FAILED
    ) {
      setPolling(false)
      clearInterval(timer.current)
      /* 
        When completed just update local state to setup completed page, this page is only visible after 
        success script run. Next time user comes in wizard is already marked as complete
       */
      if (inlineDeploymentStatus === INLINE_DEPLOYMENT_STATUSES.SUCCEEDED) {
        dispatch(setSetupFlowStep(SetupFlowSteps.setupCompleted))
      }
    }
  }, [dispatch, inlineDeploymentStatus])

  const onExit = useCallback(() => {
    dispatch(setEmailServerSettings({ completed: false }))
    setIsSaveAndExit(true)
  }, [dispatch])

  const onNext = useCallback(async () => {
    dispatch(resetSetupFlowError())
    params.delete('error')
    params.delete('idp_error')
    params.delete('idp_error_error')
    setParams(params, { replace: true })
    switch (currentStep) {
      case SetupFlowSteps.introduction:
        dispatch(saveSetupFlowStep(SetupFlowSteps.microsoftConnected))
        return
      case SetupFlowSteps.microsoftConnected:
        dispatch(saveSetupFlowStep(SetupFlowSteps.regionSelection))
        return
      case SetupFlowSteps.regionSelection:
        if (selectedRegion) {
          dispatch(
            setRegion({ region: selectedRegion.code.replace('GB', 'UK'), nextStep: SetupFlowSteps.verifyDomain })
          )
        } else {
          dispatch(setSelectedRegionError(true))
        }
        return
      case SetupFlowSteps.verifyDomain: {
        if (verifiedDomains) {
          dispatch(msVerifyDomain(SetupFlowSteps.deploymentSetup))
        } else {
          const isValidEmail = isEmailValid(trim(emailAddress))
          setIsEmailInputError(false)
          setIsMissedServerDetection(false)
          setIsMissedServerVerify(false)

          switch (true) {
            case !isValidEmail:
              dispatch(setIsEmailInputError(true))
              break
            case isValidEmail && !defaultEmailServerConfig:
              dispatch(setIsMissedServerDetection(true))
              break
            case !defaultEmailServerConfig?.verified:
              dispatch(setIsMissedServerVerify(true))
              break
            default:
          }

          const isSectionHasError =
            Object.values(verifyDomainState).some(verifyDomainSerror => verifyDomainSerror) ||
            (!!emailServerConfig && !defaultEmailServerConfig?.server?.length)
          if (emailServerVerified && !isSectionHasError) {
            dispatch(setEmailServerSettings({ completed: false, nextStep: SetupFlowSteps.deploymentSetup }))
          }
        }
        return
      }
      case SetupFlowSteps.deploymentSetup:
        if (deploymentSetup === DeploymentSetupOptions.mxRecord) {
          dispatch(saveDeploymentMethod({ step: SetupFlowSteps.emailFlow }))
        } else if (deploymentSetup === DeploymentSetupOptions.inline) {
          routesConfig.SETUP_FLOW_IPIR_AUTHORIZE.goto({}, false, `?appType=${IPIRAppTypes.InlineMx}`)
        }
        return
      case SetupFlowSteps.emailFlow: {
        if (deploymentSetup === DeploymentSetupOptions.inline) {
          if (user && accessToken) {
            setIntercomUser(user, accessToken, { company: { finished_wizard: true } })
          }
          dispatch(resetInlineDeployment())
          dispatch(startInlineDeployment())
        } else if (deploymentSetup === DeploymentSetupOptions.mxRecord) {
          const newMxVerified =
            !!mxRecords && !Object.values(mxRecords)?.some(record => !record.verified) && !newMxRecordsError
          const oldMxVerified =
            !!oldMxRecords && !Object.values(oldMxRecords)?.some(record => !record.verified) && !oldMxRecordsError

          if (!newMxVerified) {
            dispatch(setNewMxRecordsError('MX record needs to be verified to complete setup'))
          }

          if (!oldMxVerified) {
            dispatch(setOldMxRecordsError('Removal of old MX records needs to be verified to complete setup'))
          }

          if (newMxVerified && oldMxVerified) {
            if (user && accessToken) {
              setIntercomUser(user, accessToken, { company: { finished_wizard: true } })
            }
            dispatch(setEmailServerSettings({ completed: true }))
          }
        }
        break
      }
      case SetupFlowSteps.setupCompleted: {
        dispatch(setEmailServerSettings({ completed: true }))
        break
      }
      default:
    }
  }, [
    dispatch,
    currentStep,
    emailServerVerified,
    selectedRegion,
    verifyDomainState,
    emailServerConfig,
    defaultEmailServerConfig,
    emailAddress,
    mxRecords,
    newMxRecordsError,
    oldMxRecords,
    oldMxRecordsError,
    accessToken,
    user,
    params,
    deploymentSetup,
    verifiedDomains,
    setParams
  ])

  const steps = useMemo(() => {
    const keys = Object.keys(SetupFlowSteps)
    return keys.slice(0, keys.length - 1).map(step => {
      const result: StepInfo = { name: step }

      // Microsoft connected skip
      if (
        step === SetupFlowSteps.microsoftConnected &&
        currentStep !== SetupFlowSteps.introduction &&
        currentStep !== SetupFlowSteps.microsoftConnected &&
        !emailServerSettings?.ipirAccountId &&
        !emailServerSettings?.ipirTokenId
      ) {
        result.skipped = true
      }

      if (currentStep === step) {
        result.active = true
      }
      return result
    })
  }, [currentStep, emailServerSettings])

  return useMemo(
    () => [
      {
        currentStep,
        stepInProgress:
          isSaveSetupFlowStepInProgress ||
          isSaveDeploymentMethodInProgress ||
          isSetSelectedRegionInProgress ||
          isSaveSettingsInProgress ||
          saveMsDomainInProgress ||
          isRedirecting,
        loading: isSaveSetupFlowVersionInProgress || isIpirScanStatusInProgress,
        inlineDeploymentInProgress: polling || startInlineDeploymentInProgress,
        completeSetupButton:
          (currentStep === SetupFlowSteps.emailFlow && deploymentSetup === DeploymentSetupOptions.mxRecord) ||
          currentStep === SetupFlowSteps.setupCompleted,
        nextDisabled:
          currentStep === SetupFlowSteps.microsoftConnected &&
          !emailServerSettings?.ipirAccountId &&
          !emailServerSettings?.ipirTokenId,
        steps
      },
      {
        onExit,
        onNext
      }
    ],
    [
      currentStep,
      isSaveSetupFlowStepInProgress,
      isSaveDeploymentMethodInProgress,
      isIpirScanStatusInProgress,
      isSetSelectedRegionInProgress,
      isSaveSetupFlowVersionInProgress,
      isSaveSettingsInProgress,
      saveMsDomainInProgress,
      isRedirecting,
      emailServerSettings,
      polling,
      startInlineDeploymentInProgress,
      deploymentSetup,
      steps,
      onExit,
      onNext
    ]
  )
}
