import React, { useMemo } from 'react'

import { Button, Grid, MenuItem, Select, Typography } from '@barracuda-internal/bds-core'
import { useFormatMessage } from 'lib/localization'
import { GROUP_TITLE, TOPICS } from 'components/pages/support/config'
import inboundSettingsStyles from 'components/pages/inboundSettings/inboundSettingsStyles'
import InboundContentPolicies from 'components/pages/support/help/inboundContentPolicies/InboundContentPolicies'
import AttachmentTable from 'components/pages/inboundSettings/contentPolicies/AttachmentTable'
import {
  SELECT_NAMES,
  useContentPoliciesLogic
} from 'components/pages/inboundSettings/contentPolicies/useContentPoliciesLogic'
import MessageTable from 'components/pages/inboundSettings/contentPolicies//MessageTable'
import { FormSpacer, PageTitle } from 'components/libs/layout/pages/pageLayout'
import { SettingsPageContainer } from 'components/libs/layout/pages/SettingsPageContainer'

const BASE_I18N_KEY = 'ess.inbound_settings.content_policies'

const ContentPolicies: React.FC = () => {
  const inboundClasses = inboundSettingsStyles()
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const [state, eventHandlers] = useContentPoliciesLogic()

  return useMemo(
    () => (
      <SettingsPageContainer dirtyCheck isDirtyForm={state.hasPageChanges} isPageScrolled>
        <PageTitle
          title={formatMessage('title')}
          onOpenSupport={eventHandlers.helpConfig.onHelpClick}
          help={{
            content: InboundContentPolicies,
            onClose: eventHandlers.helpConfig.onCloseHelp,
            title: TOPICS.inboundContentPolicies,
            group: GROUP_TITLE.inboundSettings,
            isHelpOpen: eventHandlers.helpConfig.isOpen
          }}
        />
        <FormSpacer />
        <AttachmentTable delegateDirtyFormState={eventHandlers.setIsContentPoliciesTableDirty} />
        <Grid container alignItems="center" className={inboundClasses.selectRowWrapper}>
          <Typography variant="body2">{formatMessage('archive_filtering')}</Typography>
          <Select
            variant="outlined"
            className={inboundClasses.headerDropdown}
            name={SELECT_NAMES.ARCHIVE}
            value={state.form.password_archive_filtering}
            onChange={eventHandlers.handleOnSelectChange}
            SelectDisplayProps={{ style: { padding: 5 } }}
            data-testid="archive-field"
          >
            {state.actionOptions.map(action => (
              <MenuItem key={action} value={action}>
                {formatMessage(action)}
              </MenuItem>
            ))}
          </Select>
          <Button
            color="primary"
            onClick={() => {
              eventHandlers.onSave(SELECT_NAMES.ARCHIVE)
            }}
            variant="contained"
            size="small"
            data-testid="save-button"
          >
            {formatMessage('save')}
          </Button>
        </Grid>
        <Grid container alignItems="center" className={inboundClasses.selectRowWrapper}>
          <Typography variant="body2">{formatMessage('msoffice_filtering')}</Typography>
          <Select
            variant="outlined"
            className={inboundClasses.headerDropdown}
            name={SELECT_NAMES.MSOFFICE}
            value={state.form.password_msoffice_filtering}
            onChange={eventHandlers.handleOnSelectChange}
            SelectDisplayProps={{ style: { padding: 5 } }}
            data-testid="msoffice-field"
          >
            {state.actionOptions.map(action => (
              <MenuItem key={action} value={action}>
                {formatMessage(action)}
              </MenuItem>
            ))}
          </Select>
          <Button
            color="primary"
            onClick={() => {
              eventHandlers.onSave(SELECT_NAMES.MSOFFICE)
            }}
            variant="contained"
            size="small"
            data-testid="save-button"
          >
            {formatMessage('save')}
          </Button>
        </Grid>
        <Grid container alignItems="center" className={inboundClasses.selectRowWrapper}>
          <Typography variant="body2">{formatMessage('pdf_filtering')}</Typography>
          <Select
            variant="outlined"
            className={inboundClasses.headerDropdown}
            name={SELECT_NAMES.PDF}
            value={state.form.password_pdf_filtering}
            onChange={eventHandlers.handleOnSelectChange}
            SelectDisplayProps={{ style: { padding: 5 } }}
            data-testid="pdf-field"
          >
            {state.actionOptions.map(action => (
              <MenuItem key={action} value={action}>
                {formatMessage(action)}
              </MenuItem>
            ))}
          </Select>
          <Button
            color="primary"
            onClick={() => {
              eventHandlers.onSave(SELECT_NAMES.PDF)
            }}
            variant="contained"
            size="small"
            data-testid="save-button"
          >
            {formatMessage('save')}
          </Button>
        </Grid>
        <MessageTable delegateDirtyFormState={eventHandlers.setIsMessageTableDirty} />
      </SettingsPageContainer>
    ),
    [state, formatMessage, eventHandlers, inboundClasses]
  )
}

export default ContentPolicies
