import React, { useMemo } from 'react'
import { Alert, Box, Button, Masthead, Step, StepLabel, Stepper, Typography } from '@barracuda-internal/bds-core-v2'
import { useFormatMessage } from 'lib/localization'
import SetupFlowStepsContainer from './layout/SetupFlowStepsContainer'
import PageActionContainer from './layout/PageActionContainer'
import SetupFlowContainer from './layout/SetupFlowContainer'
import SetupFlowContentContainer from './layout/SetupFlowContentContainer'
import Introduction from './views/introduction/Introduction'
import { useSetupFlowLogic } from './useSetupFlowLogic'
import PageContainer from './layout/PageContainer'
import { SetupFlowSteps } from './types/egdWizardTypes'
import DeploymentSetup from './views/deploymentSetup/DeploymentSetup'
import MicrosoftConnected from './views/microsoftConnected/MicrosoftConnected'
import RegionSelection from './views/regionSelection/RegionSelection'
import LoadingOverlayComponent from './components/LoadingOverlayComponent'
import LoadingComponent from './components/LoadingComponent'
import SetupCompleted from './views/setupCompleted/SetupCompleted'
import VerifyDomainSelector from './views/verifyDomainComponents/VerifyDomainSelector'
import EmailFlowSelector from './views/emailFlow/EmailFlowSelector'

const BASE_I18N_KEY = 'ess_wizard'

const SetupFlow: React.FC = () => {
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const [state, eventHandlers] = useSetupFlowLogic()

  return useMemo(
    () => (
      <Box sx={{ height: '100%' }}>
        {state.stepInProgress && <LoadingOverlayComponent />}
        {state.inlineDeploymentInProgress && (
          <LoadingOverlayComponent description={formatMessage('inline_deployment_loading')} />
        )}
        <PageContainer>
          {state.loading && <LoadingComponent />}
          {!state.loading && (
            <Box sx={{ height: '100%' }}>
              <Masthead
                productTitle={formatMessage('email_protection')}
                pageTitle={formatMessage('setup')}
                rightContent={
                  <PageActionContainer>
                    <Button onClick={eventHandlers.onExit} bdsType="interactiveNeutral">
                      {formatMessage('exit')}
                    </Button>
                    <Button disabled={state.nextDisabled} onClick={eventHandlers.onNext} bdsType="interactiveEmphasis">
                      {state.completeSetupButton ? formatMessage('complete_setup') : formatMessage('next')}
                    </Button>
                  </PageActionContainer>
                }
              />
              <SetupFlowContainer>
                <SetupFlowContentContainer>
                  <SetupFlowStepsContainer>
                    <Stepper orientation="vertical" activeStep={state.steps.findIndex(step => step.active)}>
                      {state.steps.map(step => (
                        <Step completed={step.skipped === true ? false : undefined} key={step.name}>
                          <StepLabel
                            optional={
                              step.skipped === true ? (
                                <Typography variant="caption">{formatMessage('skipped')}</Typography>
                              ) : null
                            }
                          >
                            {formatMessage(`${step.name}_step`)}
                          </StepLabel>
                        </Step>
                      ))}
                    </Stepper>
                  </SetupFlowStepsContainer>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '10px'
                    }}
                  >
                    {state.currentStep === SetupFlowSteps.introduction && <Introduction />}
                    {state.currentStep === SetupFlowSteps.deploymentSetup && <DeploymentSetup />}
                    {state.currentStep === SetupFlowSteps.microsoftConnected && <MicrosoftConnected />}
                    {state.currentStep === SetupFlowSteps.regionSelection && <RegionSelection />}
                    {state.currentStep === SetupFlowSteps.verifyDomain && <VerifyDomainSelector />}
                    {state.currentStep === SetupFlowSteps.emailFlow && <EmailFlowSelector />}
                    {state.currentStep === SetupFlowSteps.setupCompleted && <SetupCompleted />}
                  </Box>
                </SetupFlowContentContainer>
              </SetupFlowContainer>
            </Box>
          )}
        </PageContainer>
      </Box>
    ),
    [state, eventHandlers, formatMessage]
  )
}

export default SetupFlow
