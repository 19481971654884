import React, { useMemo } from 'react'
import { Alert, Box, SelectMenuItem, SelectWrapper, useTokens } from '@barracuda-internal/bds-core-v2'
import { useFormatMessage } from 'lib/localization'
import { useRegionSelectionLogic } from 'components/pages/setupFlow/views/regionSelection/useRegionSelectionLogic'
import SetupFlowStepsContentContainer from '../../layout/SetupFlowStepsContentContainer'
import ContentTitleComponent from '../../components/ContentTitleComponent'
import ErrorComponent from '../../components/ErrorComponent'

const BASE_I18N_KEY = 'ess_wizard.region_selection'

const RegionSelection = () => {
  const tokens = useTokens()
  const [state, eventHandlers] = useRegionSelectionLogic()
  const formatMessage = useFormatMessage(BASE_I18N_KEY)

  return useMemo(
    () => (
      <SetupFlowStepsContentContainer>
        <ContentTitleComponent title={formatMessage('selector_title')} subtitle={formatMessage('selector_subtitle')} />
        <ErrorComponent />
        <Box
          sx={{
            padding: tokens.aliasesSpaceContainerDefault
          }}
        >
          <Alert bdsBoxShadowType="inline" bdsType="neutralWarning">
            {formatMessage('selector_warning')}
          </Alert>
        </Box>
        <Box
          sx={{
            paddingRight: tokens.aliasesSpaceContainerDefault,
            paddingLeft: tokens.aliasesSpaceContainerDefault,
            display: 'flex'
          }}
        >
          <SelectWrapper
            fullWidth
            bdsContainerType="container"
            error={state.selectedRegionError}
            onChange={eventHandlers.onSelectRegion}
            value={state.selectedRegion?.code || ''}
            label={formatMessage('region')}
            helperText={state.selectedRegionError ? formatMessage('missed_region_error') : ''}
          >
            {state.countries.map(({ code, name }) => (
              <SelectMenuItem key={code} value={code}>
                {name}
              </SelectMenuItem>
            ))}
          </SelectWrapper>
        </Box>
      </SetupFlowStepsContentContainer>
    ),
    [state, eventHandlers, formatMessage, tokens]
  )
}

export default RegionSelection
