import React, { useMemo } from 'react'
import { Box, useTokens, RadioGroup, FormControlLabel, Radio } from '@barracuda-internal/bds-core-v2'
import { useFormatMessage } from 'lib/localization'
import SetupFlowStepsContentContainer from '../../../layout/SetupFlowStepsContentContainer'
import ContentTitleComponent from '../../../components/ContentTitleComponent'
import ErrorComponent from '../../../components/ErrorComponent'
import { useVerifyMsDomainLogic } from './useVerifyMsDomainLogic'

const BASE_I18N_KEY = 'ess_wizard.verify_domain'

const VerifyMsDomain = () => {
  const tokens = useTokens()
  const formatMessage = useFormatMessage(BASE_I18N_KEY)

  const [state, eventHandlers] = useVerifyMsDomainLogic()

  return useMemo(
    () => (
      <SetupFlowStepsContentContainer>
        <ContentTitleComponent title={formatMessage('title')} subtitle={formatMessage('subtitle')} />
        <ErrorComponent />
        {state.verifiedDomains && (
          <Box
            sx={{
              display: 'flex',
              padding: tokens.aliasesSpaceContainerDefault,
              gap: tokens.aliasesSpaceContainerDefault
            }}
          >
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              value={state.msDomainName}
              onChange={eventHandlers.onSelectChange}
              name="radio-buttons-group"
              sx={{
                display: 'flex',
                flexDirection: 'column',
                flexWrap: 'nowrap',
                padding: tokens.aliasesSpaceContainerDefault,
                gap: tokens.aliasesSpaceContainerDefault
              }}
            >
              {state.verifiedDomains.map(({ name }) => (
                <FormControlLabel
                  key={name}
                  control={<Radio bdsSize="small" />}
                  variant="toggle"
                  label={name}
                  value={name}
                />
              ))}
            </RadioGroup>
          </Box>
        )}
      </SetupFlowStepsContentContainer>
    ),
    [tokens, formatMessage, state, eventHandlers]
  )
}

export default VerifyMsDomain
