import { useSearchParams } from 'react-router-dom'
import { useEffect, useMemo } from 'react'

import { useFormatMessage } from 'lib/localization'
import routesConfig from 'lib/routesConfig'

export interface ErrorParamConfig {
  stayOnRoute?: boolean
  setTopErrorMessage: (message: string) => void
  setTopErrorDescription: (message: string) => void
}

const BASE_I18N_KEY = 'ess'

export const useErrorParam = (config: ErrorParamConfig) => {
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const [params] = useSearchParams()
  const error = params.get('error')
  const idp_error = params.get('idp_error')
  const errorDescription = params.get('error_description')
  const idpErrorDescription = params.get('idp_error_description')

  const errorMap: Map<string, string> = useMemo(
    () =>
      new Map([
        ['OAuth2AuthorizeParameterMissing', 'oauth2.exception.OAuth2AuthorizeParameterMissing'],
        ['OAuth2AuthorizationCodeRequestFailed', 'oauth2.exception.OAuth2AuthorizationCodeRequestFailed'],
        ['OAuth2IdentityProviderFlowFailed', 'oauth2.exception.OAuth2IdentityProviderFlowFailed'],
        ['OAuth2TokenExchangePKCEVerificationFailed', 'oauth2.exception.OAuth2TokenExchangePKCEVerificationFailed'],
        ['IdTokenGenerationFailed', 'oauth2.exception.IdTokenGenerationFailed'],
        ['EGDIdentityNotFound', 'oauth2.exception.EGDIdentityNotFound'],
        ['OAuth2AuthorizeRedirectURINotAllowed', 'oauth2.exception.OAuth2AuthorizeRedirectURINotAllowed'],
        ['token_failed', 'oauth2.exception.azure_ad_api_error_token_failed'],
        ['env_missing', 'oauth2.exception.azure_ad_api_error_env_missing'],
        ['invalid_request', 'oauth2.exception.ms_invalid_request'],
        ['unauthorized_client', 'oauth2.exception.ms_unauthorized_client'],
        ['access_denied', 'oauth2.exception.ms_access_denied'],
        ['unsupported_response_type', 'oauth2.exception.ms_unsupported_response_type'],
        ['server_error', 'oauth2.exception.ms_server_error'],
        ['temporarily_unavailable', 'oauth2.exception.ms_temporarily_unavailable'],
        ['invalid_resource', 'oauth2.exception.ms_invalid_resource'],
        ['login_required', 'oauth2.exception.ms_login_required'],
        ['interaction_required', 'oauth2.exception.ms_interaction_required'],
        ['invalid_grant', 'oauth2.exception.ms_invalid_grant'],
        ['invalid_client', 'oauth2.exception.ms_invalid_client'],
        ['unsupported_grant_type', 'oauth2.exception.ms_unsupported_grant_type'],
        ['consent_required', 'oauth2.exception.ms_consent_required'],
        ['invalid_scope', 'oauth2.exception.ms_invalid_scope'],
        ['oauth2.auth_code_flow.token_exchange_failed', 'oauth2.auth_code_flow.token_exchange_failed'],
        ['Invalid_domain', 'oauth2.exception.tdf_invalid_domain'],
        ['This_domain_is_not_verified', 'oauth2.exception.tdf_domain_not_verified'],
        ['OAuth2ProcessNotFound', 'oauth2.exception.OAuth2ProcessNotFound']
      ]),
    []
  )

  useEffect(() => {
    if (error) {
      const mappedError = errorMap.get(error)
      const mappedIdpError = idp_error && errorMap.get(idp_error)
      let displayError = idp_error || error
      if (mappedIdpError) {
        displayError = formatMessage(mappedIdpError)
      } else if (mappedError) {
        displayError = formatMessage(mappedError)
      }
      config.setTopErrorMessage(displayError)
      config.setTopErrorDescription(idpErrorDescription || errorDescription || '')
      params.delete('error')
      params.delete('idp_error')
      params.delete('error_description')
      params.delete('idp_error_description')
      if (!config.stayOnRoute) {
        routesConfig.LOGIN.softGoto(undefined, false, `?${params.toString()}`)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}
