import OVERVIEW_TAB_EN from 'config/i18n/tabs/overview_en'
import INBOUND_SETTINGS_TAB_EN from 'config/i18n/tabs/inbound_settings_en'
import OUTBOUND_SETTINGS_TAB_EN from 'config/i18n/tabs/outbound_settings_en'
import USERS_TAB_EN from 'config/i18n/tabs/users_en'
import SUPPORT_EN from 'config/i18n/support_en'
import DOMAINS_TAB_EN from 'config/i18n/tabs/domains_en'

const EMAIL = 'Email'
const HEADERS = 'Headers'
const MESSAGE_LOG = 'Message Log'
const SETTINGS = 'Settings'
const SUPPORT = 'Support'
const SENDER = 'Sender'
const SUBJECT = 'Subject'
const DOMAINS = 'Domains'
const OVERVIEW = 'Overview'
const INBOUND_SETTINGS = 'Inbound'
const OUTBOUND_SETTINGS = 'Outbound'
const ATP_SETTINGS = 'ATP'
const USERS = 'Users'
const REPORTS = 'Reports'
const ACCOUNT_MANAGEMENT = 'Syslog'
const SEARCH = 'Search'
const CLOSE = 'Close'
const SAVE = 'Save'
const CANCEL = 'Cancel'

/* eslint-disable quotes */
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  ess: {
    overview: OVERVIEW_TAB_EN,
    inbound_settings: INBOUND_SETTINGS_TAB_EN,
    outbound_settings: OUTBOUND_SETTINGS_TAB_EN,
    users: USERS_TAB_EN,
    domains: DOMAINS_TAB_EN,

    account_switcher: {
      switching_account: 'Switching to "{account}" account ...',
      cancel: CANCEL
    },
    ui_switcher: {
      new_interface: 'New Email Gateway Defense'
    },
    nivo: {
      choropleth_tooltip_attacks: '{count, plural, =0 {Attacks} one {Attack} other {Attacks}}'
    },
    support_page: SUPPORT_EN,
    layout: {
      yes: 'Yes',
      no: 'No',
      cancel: CANCEL,
      save_changes: 'Save changes',
      search: SEARCH,
      unsaved_dialog: {
        title: 'Unsaved changes',
        text: 'There are unsaved changes on this page. Do you wish to continue?'
      },
      global_dialog: {
        not_supported_role_type: {
          title: 'Redirecting to classic interface',
          text: 'Users with Domain Admin or Help Desk privileges are currently only supported in the Classic interface.',
          button: 'Redirect'
        },
        invalid_access: {
          title: 'Invalid access',
          text: 'The Enhanced Security feature (SSO) is not enabled. Please contact Barracuda Networks Technical Support.',
          button: 'Ok'
        }
      }
    },
    app: {
      error: {
        default: 'Oops! Something went wrong',
        failed_to_load_content: 'Failed to load content',
        add_domain_error_duplicate: 'This domain name has already been added.',
        add_domain_error_exist_in_other_account: 'This domain already added and verified by another account',
        add_domain_error_alias:
          'Domain is alias to {parent_domain_name}. SSDM will remain blocked until the relationship is removed.',
        add_domain_error_quarantine_notifier: 'Failed to create Quarantine Notifier settings',
        add_domain_error_blacklisted_domain_name: 'Blacklisted domain name',
        add_domain_failure: 'Failed to add domain',
        delete_domain_failure_not_found: 'The domain to be deleted was not found',
        delete_domain_failure_quarantine_notifier:
          'Domain has been deleted, but deleting related Quarantine Notifier settings failed',
        manage_domain_failure_forbidden: "You don't have sufficient privileges to manage the selected domain",
        edit_domain_failure_forbidden: "You don't have sufficient privileges to edit the selected domain",
        get_domain_failure: 'An error occurred while fetching the domain data'
      }
    },
    loading: 'Loading...',
    expiration_banner: {
      free_trial_expires: 'Free trial expired'
    },
    global_message_banner: {
      impersonated_user_text: 'Logged in as: <b>{user}</b>.',
      impersonated_user_button: 'Log out',
      pd_domain_id_text: 'You are now managing settings for <b>{domain}</b>.',
      pd_domain_id_text_helpdesk: 'You are performing a help desk role for <b>{domain}</b>',
      pd_domain_id_button: 'Return to domain management.',
      wizard_text: 'It looks like you have not yet completed the set up of Email Gateway Defense.',
      wizard_link: 'Set up',
      wizard_complete_text: 'Setup completed. Waiting for mail to flow in...',
      account_disabled: {
        text: 'Your account is expired and has been disabled.'
      },
      account_suspended: {
        text: 'Your account is expired and has been suspended.'
      },
      account_expired: {
        text: 'Your account has expired. Please contact <a>Barracuda Networks Sales</a> to renew your subscription to avoid mail flow disruptions.'
      },
      account_terminated: {
        text: 'Your account has expired. Please contact <a>Barracuda Networks Sales</a> to renew your subscription to avoid mail flow disruptions.'
      },
      trial_expired: {
        text: 'Your trial has expired. Please contact <a>Barracuda Networks Sales</a> to purchase a subscription to avoid mail flow disruptions.'
      },
      trial_expiring: {
        text: 'You have {trial_days_remaining} day(s) until your free trial expires on {expiry_date}'
      }
    },
    banners: {
      impersonated_account_admin: 'Admin logged in as: {accountId}. <link>Log out of this account</link>'
    },
    advanced_search: {
      keywords: 'Keywords',
      from: 'From',
      envelope_from: 'Envelope From',
      to: 'To',
      envelope_to: 'Envelope To',
      subject: 'Subject',
      attachment_name: 'Attachment Name',
      start_date: 'Start Date',
      end_date: 'End Date'
    },
    save_search: {
      title: 'Save Search',
      must_enter_name: 'You must enter a name',
      valid_name: ' ',
      name_your_search: 'Name your saved search...'
    },
    open_search: {
      title: 'Open Search',
      subtitle: 'Name',
      find_your_search: 'Find your saved search...'
    },
    login: {
      title: 'Sign in',
      signin_message: 'Please sign in to access your messages and settings in Email Gateway Defense',
      signin_message_email: 'Please enter the password for <b>{email}</b>',
      invalid_email: 'Invalid email address and/or password',
      password: 'Password',
      email: 'Email address',
      reset_password_message: 'Password successfully reset',
      valid_email_message: 'Please enter a valid email address',
      signin: 'Sign in',
      next: 'Next',
      different_account_message: 'Sign in to a different account',
      forgot_password: 'Forgot password?',
      admin_signin: 'Administrator sign in',
      signing_in: 'Please wait...',
      expired_message: 'The link to login has expired',
      is_not_loading: 'Redirecting to classic interface',
      invalid_link:
        'Unable to load the new user interface on Email Gateway Defense due to a technical difficulty, we are redirecting you to the classic user interface on Email Gateway Defense.',
      redirect: 'Redirect',
      login_failed: 'Login was not successful',
      new_link_sent: 'A new link has been sent to your email',
      signin_shared_mailbox: 'Signing into a shared mailbox or distribution list?',
      signin_temporary_passcode: 'We can <a>email a temporary passcode</a> for you to sign in with.',
      temporary_passcode_title: 'Enter temporary passcode',
      temporary_passcode_text: 'We’ve sent a temporary passcode to {email}. Passcode is valid for 15 minutes.',
      havent_received_email: 'Haven’t received an email?',
      resend_temporary_passcode:
        'It might take a few minutes. If it’s been a while we can <a>resend a temporary passcode</a>.',
      already_have_passcode: 'Already have a temporary passcode?',
      temporary_passcode: 'Temporary passcode',
      invalid_temporary_login: 'Passcode is either invalid or expired. Please check your email and try again.',
      temporary_passcode_sent: 'A temporary passcode has been sent to your email. This might take a few minutes.',
      temporary_passcode_limit:
        'You’ve reached the limit of {rateLimit} temporary passcodes per {expirationTime} minute session. Please check your email to find the most recent temporary passcode.',
      temporary_feature_not_enabled:
        'The temporary passcode feature is not enabled for {domainName}. Please check with your admin.',
      internal_server_error: 'Internal server error',
      message: {
        title: 'Check your mail',
        sent_message: 'An email with a link to reset your password has been sent to {email}.',
        expiry_message: 'Link expires in 1 hour.',
        resend: 'Resend link'
      },
      azure_temporary_passcode_title: 'Sign in options',
      continue: 'Continue',
      welcome: 'Sign in',
      welcome_message: 'Sign in to Email Gateway Defense to access your messages and settings',
      go_to_signin: 'Back to sign in',
      option_divider_text: 'OR',
      resend_link: 'Resend link',
      sign_in_with_microsoft: 'Continue with Microsoft',
      email_a_temporary_passcode: 'Email me a temporary passcode',
      password_for: 'Enter password for {email}',
      signin_option_for: 'Select sign in option for {email}',
      didnt_receive_email: "Didn't receive an email?",
      resend_code: 'Resend code'
    },
    bcc_login: {
      error_page: {
        401: {
          title: 'Admin cannot be authorized',
          subtitle: 'please try again with valid credentials.'
        },
        default: {
          title: 'Server Error',
          subtitle: 'Please contact support.'
        }
      }
    },
    reset_password: {
      title: 'Reset password',
      reset_password_message: 'Please enter new password for {email}',
      password1: 'New password',
      password2: 'Confirm new password',
      password_matches: 'Password matches',
      password_not_match: 'Password does not match',
      new_link_sent: 'A new link has been sent to your email',
      reset: 'Reset',
      expired_link: {
        title: 'Expired link',
        message: 'The link to reset password for {email} has expired.',
        sign_in: 'Back to sign in',
        resend_link: 'Resend link'
      },
      check_email: {
        title: 'Check your email',
        message: 'An email with a link to reset your password has been sent to {email}.',
        link_will_expire: 'Link expires in 1 hour.'
      },
      successful_reset: {
        title: 'Your password has been successfully reset.',
        sign_in: 'Sign in'
      },
      error: {
        send_login_info_failed: 'Failed to send login info',
        resend_link_failed: 'Failed to resend link',
        password_reset_failed: 'Failed to reset the password'
      },
      didnt_receive_email: "Didn't receive an email?",
      resend_link: 'Resend link'
    },
    button: {
      cancel: CANCEL,
      save: 'Save Changes'
    },
    dialog: {
      title: 'Dialog',
      confirm_title: 'Confirmation',
      info_title: 'Information',
      error_title: 'Error',
      cancel: CANCEL,
      ok: 'OK',
      yes: 'Yes',
      no: 'No',
      submit: 'Submit',
      save: SAVE,
      open: 'Open',
      search: SEARCH,
      okay: 'Okay',
      unsaved_changes: 'There are unsaved changes on this page. Do you wish to continue?',
      antivirus_off: 'Turning off Anti-Virus will disable ATP',
      domain_settings_conflict: 'This change conflicts with {numberOfDomains} domains.',
      domain_settings_conflict_description:
        'Settings at the domain level take precendence over the account level. Saving these changes will not take effect for those domains.',
      confirm_removal: 'Confirm removal?'
    },
    reports: {
      title: 'Reports',
      search: SEARCH,
      domain: 'Domain',
      email_flow: 'Email flow',
      inbound: 'Inbound',
      outbound: 'Outbound',
      display: 'Display',
      all_domains: 'All domains',
      top: 'Top {count}',
      display_all: 'All',
      unable_to_load: 'Unable to load',
      no_data_found: 'No data found',
      invalid_date: 'Invalid date range',
      shortcut_items: {
        last_day: 'Last day',
        last_7_days: 'Last 7 days',
        last_30_days: 'Last 30 days',
        reset: 'Reset'
      },
      intervals: {
        hourly: 'Hourly intervals',
        daily: 'Daily intervals',
        weekly: 'Weekly intervals'
      },
      conditions_equals: 'Equal to',
      conditions_not_equals: 'Not equal to',
      report_types: {
        barracuda: 'Barracuda Reports',
        custom: 'My Reports'
      },
      predefined_report_types: {
        inbound_traffic_summary: 'Inbound traffic summary',
        outbound_traffic_summary: 'Outbound traffic summary',
        inbound_blocked_emails_breakdown: 'Inbound blocked emails breakdown',
        top_inbound_email_senders: 'Top inbound email senders',
        top_inbound_email_recipients: 'Top inbound email recipients',
        top_inbound_blocked_senders_breakdown: 'Top inbound blocked senders breakdown',
        top_inbound_blocked_recipients_breakdown: 'Top inbound blocked recipients breakdown',
        top_outbound_email_senders: 'Top outbound email senders',
        top_outbound_blocked_senders: 'Top outbound blocked senders'
      },
      tabs: {
        reports: 'Reports',
        report_list: 'Report list',
        scheduled_reports: 'Scheduled reports'
      },
      title_bar: {
        download_button: 'Export as',
        save_as: 'Save as',
        download_csv: 'Download as CSV',
        download_pdf: 'Download as PDF',
        schedule_report: 'Schedule report',
        close: CLOSE,
        save: SAVE,
        save_report_dialog: {
          title: 'Save report',
          subtitle:
            "Enter a name to save this report and it's current selected parameters.  You can access this report in the future under My Reports.",
          placeholder_text: 'Report Name',
          date_warning:
            'The data you’re saving from {startDate} to {endDate} will be permanently deleted after {deleteDate}. This is due to our {retentionPolicyDayRange} day data retention policy. To retain access to this data, download it as CSV or PDF before {expirationDate}.',
          expired_date_range: 'The selected date range is unreachable. Select a valid time range to save this report'
        }
      },
      labels: {
        report_date: 'Date',
        email: 'Email',
        sender_email: 'Sender email',
        recipient_email: 'Recipient email',
        total_deferred: 'Total deferred',
        total_blocked: 'Total blocked',
        total_quarantined: 'Total quarantined',
        total_encrypted: 'Total encrypted',
        total_allowed: 'Total allowed',
        blocked_by_rate_control: 'Blocked by rate control',
        blocked_by_policy: 'Blocked by policy',
        blocked_by_spam: 'Blocked by spam',
        blocked_for_virus: 'Blocked for virus',
        blocked_by_atp: 'Blocked by ATP',
        blocked_other: 'Blocked other'
      },
      schedule_report: {
        title: 'Schedule report',
        report_name: 'Report name',
        time_range: 'Time range',
        format: 'Format',
        last_month: 'Last 30 days',
        last_week: 'Last 7 days',
        last_day: 'Last day',
        csv: 'Data CSV',
        frequency: 'Frequency',
        daily: 'Daily',
        weekly: 'Weekly',
        monthly: 'Monthly',
        recipients: 'Recipients',
        cancel: CANCEL,
        schedule: 'Schedule',
        on: 'On',
        day: 'Day',
        every: 'Every',
        first: 'First',
        second: 'Second',
        third: 'Third',
        fourth: 'Fourth',
        last: 'Last',
        specific_date: 'Specific date',
        1: 'Mo',
        2: 'Tu',
        3: 'We',
        4: 'Th',
        5: 'Fr',
        6: 'Sa',
        7: 'Su',
        specific_dates: {
          First: '1st',
          Second: '2nd',
          Third: '3rd',
          Fourth: '4th',
          Fifth: '5th',
          Sixth: '6th',
          Seventh: '7th',
          Eighth: '8th',
          Ninth: '9th',
          Tenth: '10th',
          Eleventh: '11th',
          Twelfth: '12th',
          Thirteenth: '13th',
          Fourteenth: '14th',
          Fifteenth: '15th',
          Sixteenth: '16th',
          Seventeenth: '17th',
          Eighteenth: '18th',
          Nineteenth: '19th',
          Twentieth: '20th',
          TwentyFirst: '21st',
          TwentySecond: '22nd',
          TwentyThird: '23rd',
          TwentyFourth: '24th',
          TwentyFifth: '25th',
          TwentySixth: '26th',
          TwentySeventh: '27th',
          TwentyEighth: '28th',
          TwentyNinth: '29th',
          Thirtieth: '30th',
          ThirtyFirst: '31st',
          Last: 'Last day of month'
        },
        invalid_recipient: 'The recipient with email address {email} is not valid.',
        missing_recipients: 'Please provide recipients.',
        too_many_recipients: 'Too many recipients, the maximum number of allowed recipients is 50.',
        duplicate_recipient: '{email} is duplicate recipient.'
      },
      scheduled_reports: {
        title: 'Scheduled reports',
        new_schedule: 'New schedule',
        search: 'Search',
        sent: 'Sent',
        disabled: 'Disabled',
        processing: 'Processing',
        csv: 'CSV',
        pdf: 'PDF',
        last_month: 'Last 30 days',
        last_week: 'Last 7 days',
        last_day: 'Last day',
        daily: 'Daily',
        weekly: 'Weekly',
        monthly: 'Monthly',
        scheduled_time: 'report scheduled at {time} for',
        next_schedule: 'Next scheduled delivery:',
        timezone: '(Timezone: {timezone})',
        last_schedule: 'Last sent on:',
        today: 'Today',
        at: 'at',
        disable_schedule: 'Disable schedule',
        enable_schedule: 'Enable schedule',
        delete_schedule: 'Delete schedule',
        open_report: 'Open report',
        more: 'more'
      }
    },
    settings: {
      title: 'Settings',
      tabs: {
        quarantine_notification: 'Quarantine Notification',
        sender_policies: 'Sender Policy',
        linked_accounts: 'Linked Accounts',
        change_password: 'Change Password'
      },
      bulk_edit: {
        buttons: {
          save: 'Save Changes',
          cancel: CANCEL
        },
        rate_control_title: 'Bulk Edit: Rate Control Exemptions',
        custom_rbls_title: 'Bulk Edit: Custom RBLs',
        ip_policies_title: 'Bulk Edit: IP Address Policies',
        trusted_forwarders_title: 'Bulk Edit: Trusted Forwarders',
        geoip_policies_title: 'Bulk Edit: GeoIP Policies',
        language_policies_title: 'Bulk Edit: Language Policies',
        recipient_policies_title: 'Bulk Edit: Recipient Policies',
        sender_policies_title: 'Bulk Edit: Sender Policies',
        dmarc_exempt_title: 'Bulk Edit: DMARC Exempt',
        dkim_exempt_title: 'Bulk Edit: DKIM Exempt',
        spf_exempt_title: 'Bulk Edit: SPF Exemptions by IP Address',
        spf_domain_exempt_title: 'Bulk Edit: SPF Exemptions by Domain',
        spf_missing_exempt_title: 'Bulk Edit: Missing SPF Exempt',
        ptr_exempt_title: 'Bulk Edit: PTR Exempt',
        attachment_filter_title: 'Bulk Edit: Attachment Filter',
        message_filter_title: 'Bulk Edit: Message Content Filter',
        intent_domains_title: 'Bulk Edit: Intent Domain Policies',
        predefined_filters_title: 'Bulk Edit: Subject',
        predefined_filters_exceptions_title: 'Bulk Edit: Predefined Filter Exceptions',
        dlp_encryption_title: 'Bulk Edit: DLP/Encryption',
        sender_ip_title: 'Bulk Edit: Sender IP Address Ranges',
        inbound_tls_domains_title: 'Bulk Edit: Forced TLS Domains',
        inbound_tls_domain_exemptions_title: 'Bulk Edit: Exempt TLS Domains',
        geoip_sender_exempt_title: 'Bulk Edit: GeoIP Exemptions by Email Address/Domains',
        geoip_exempt_title: 'Bulk Edit: GeoIP Exemptions by Sender IP Address',
        machine_learning_exempt_title: 'Bulk Edit: Machine Learning Exemptions by Email Address/Domain',
        place_holder: 'Write or past policies here',
        title: 'Bulk Edit: Sender Policies',
        header: 'Sender (Email Address, Domain or User),Policy (block, exempt, quarantine),Comment (optional)',
        custom_rbls_header: 'Host Name,Comment (optional)',
        control_rate_header: 'IP Address,Netmask,Comment (optional)',
        ip_policy_header: 'IP Address,Netmask,Policy (block, exempt),Comment (optional)',
        trusted_forwarders_header: 'IP Address,Netmask,Comment (optional)',
        geoip_policies_header: 'Country (ISO ALPHA-3),Policy (block, quarantine),Comment (optional)',
        language_policies_header: 'Language,Policy (block, quarantine),Comment (optional)',
        recipient_policies_header: 'Email Address,Policy (scan, exempt),Comment (optional)',
        sender_policies_header_cpl: 'Email Address,Policy (block, exempt),Comment (optional)',
        sender_policies_header: 'Email Address,Policy (block, exempt, quarantine),Comment (optional)',
        geoip_exempt_header: 'IP Address,Netmask,Comment (optional)',
        geoip_sender_exempt_header: 'Email/Domain,Comment (optional)',
        dmarc_exempt_header: 'Domain,Comment (optional)',
        dkim_exempt_header: 'Domain,Comment (optional)',
        spf_exempt_header: 'IP Address,Netmask,Comment (optional)',
        spf_domain_exempt_header: 'Domain,Comment (optional)',
        spf_missing_exempt_header: 'IP Address,Netmask,Comment (optional)',
        ptr_exempt_header: 'IP Address,Netmask,Comment (optional)',
        sender_ip_header: 'Logging & Policy Domain,IP Address,Netmask,Comment (Optional)',
        machine_learning_exempt_header: 'Email/Domain,Comment (optional)',
        attachment_filter_header:
          'Filter Type (filename/mime),Pattern (Wilcard expression or mime type),Check Archives (1/0),Action (block/ignore/quarantine),Comment (optional)',
        attachment_filter_header_cpl:
          'Filter Type (filename/mime),Pattern (Wilcard expression or mime type),Check Archives (1/0),Action (block/ignore),Comment (optional)',
        message_filter_header:
          'Pattern (regular expression),Action (block/allow/quarantine),Match Filter (Comma-separated list of: subject, headers, body, attachments, sender, recipient)',
        outbound_message_filter_header:
          'Pattern (regular expression),Action (block/allow/quarantine/encrypt/not_encrypt),Match Filter (Comma-separated list of: subject, headers, body, attachments, sender, recipient)',
        message_filter_header_cpl:
          'Pattern (regular expression),Action (block/allow),Match Filter (Comma-separated list of: subject, headers, body, attachments, sender, recipient)',
        intent_domains_header: 'Domain,Policy (ignore/block/quarantine),Comment (optional)',
        intent_domains_header_cpl: 'Domain,Policy (ignore/block),Comment (optional)',
        predefined_filters_header_cpl:
          'Pattern (creditcard/ssn/privacy/hipaa),Action (block),Match Filter (Comma-separated list of: subject, headers, body, attachments)',
        predefined_filters_header:
          'Pattern (creditcard/ssn/privacy/hipaa),Action (block/quarantine/encrypt),Match Filter (Comma-separated list of: subject, headers, body, attachments)',
        predefined_filters_exception_header: 'Type (phone_number/address),Pattern',
        dlp_encryption_header: 'Domain Name,Comment (optional)',
        inbound_tls_domains_header: 'Domain Name,Comment (optional)',
        inbound_tls_domain_exemptions_header: 'Domain Name,Comment (optional)',
        sub_title:
          'Bulk Edit allows you to perform multiple changes (additions, subtractions, modifications) to a list of configuration settings in one step.',
        content_header: 'Editing the CSV Data',
        content_sub_header:
          'Many columns can only accept specific forms of data. For instance, columns associated with email addresses must contain data that appears in the format of an email address. Some columns are associated with data that is optional or has associated default values, and may be left empty.',
        warning:
          'Note: Please make sure that the first line does not contain any valid data as it is not parsed. Use column titles on the first line if desired.',
        error_missing_policy: 'Error on row %d (Missing policy)',
        error_invalid_name: 'Error on row %d (Invalid name)',
        error_invalid_policy: 'Error on row %d (Invalid policy)',
        error_missing_zone: 'Error on row {row} (Missing zone)',
        error_invalid_zone: 'Error on row {row} (Invalid zone)',
        error_missing_address: 'Error on row {row} (Missing address)',
        error_invalid_address: 'Error on row {row} (Invalid address)',
        error_missing_netmask: 'Error on row {row} (Missing netmask)',
        error_invalid_netmask: 'Error on row {row} (Invalid netmask)',
        error_missing_ip_policy: 'Error on row {row} (Unspecified policy)',
        error_invalid_ip_policy: 'Error on row {row} (Invalid policy)',
        error_missing_country: 'Error on row {row} (Missing country)',
        error_invalid_country: 'Error on row {row} (Invalid country code)',
        error_missing_email: 'Error on row {row} (Missing email address)',
        error_invalid_email: 'Error on row {row} (Invalid email address: {item})',
        error_missing_domain: 'Error on row %d (Missing domain)',
        error_invalid_domain: 'Error on row %d (Invalid domain)',
        error_missing_domain_name: 'Error on row %d (Please specify a domain name)',
        error_invalid_ip_address: 'Error on row %d (Please enter a valid IP address)',
        error_on_row: 'Error on row {row}',
        error_missing_filter_type: 'Missing filter type',
        error_invalid_filter_type: 'Invalid filter type',
        error_missing_filter_pattern: 'Missing filter pattern',
        error_missing_predefined_filter_pattern: 'Missing predefined-pattern',
        error_invalid_filter_pattern: 'Invalid filter pattern',
        error_missing_archive_check: 'Missing archive check',
        error_invalid_archive_check: 'Invalid archive check',
        error_missing_filter_action: 'Missing filter action',
        error_invalid_filter_action: 'Invalid filter action',
        error_missing_match_filter: 'At least one match filter must be specified',
        error_invalid_match_filter: 'Invalid match filter specified',
        error_missing_type: 'Missing type',
        error_invalid_type: 'Invalid type',
        error_invalid_phone_number: 'Invalid phone number',
        error_subnet_too_large:
          'Error on row {row} (Specified subnet range is too large. Please divide into multiple /24 subnets.)',
        error_new_entry_single_ip: 'Error on row {row} (New entries may only be single IP numbers.)',
        error_is_not_verified_domain: 'Error on row {row} (Domain name must be of a verified domain.)',
        error_already_specified:
          'Error on row {row} (The specified subnet conflicts with a subnet from another account.)',
        error_potentially_shared_infrastructure:
          'Error on row {row} (The IP address/range you selected is part of a range that has been marked as potentially shared infrastructure. You can not assign that to any specific domain.)',
        error_no_reverse_dns: 'Error on row {row} (The IP address you provided lacks reverse DNS)',
        error_conflict_subnet: 'Error on row {row} (The specified subnet conflicts with the subnet: {ip} / {netmask})',
        error_duplicate_value: 'Error on row {row} (Duplicate value: {item})',
        error_invalid_language: 'Invalid language code',
        error_missing_language: 'Missing language',
        error_missing_machine_learning_exempt: 'Missing Email Address/Domain',
        error_invalid_email_domain: 'Invalid Email or Domain'
      },
      ip_address_table: {
        ip_address: 'IP Address',
        netmask: 'Netmask',
        policy: 'Policy',
        comment: 'Comment',
        actions: 'Actions',
        add: 'Add',
        remove: 'Remove',
        confirm_removal: 'Confirm removal?',
        block: 'Block',
        exempt: 'Exempt',
        error: {
          empty_ip_address: 'Please enter a valid IP address.',
          invalid_ip_address: 'Invalid IP address.',
          empty_netmask: 'Please enter a valid netmask.',
          invalid_netmask: 'Invalid netmask.',
          duplicate_value: 'Duplicate value: {item}'
        }
      },
      domain_table: {
        domains: 'Domains',
        email_domain: 'Email/Domain',
        comment: 'Comment',
        actions: 'Actions',
        add: 'Add',
        remove: 'Remove',
        confirm_removal: 'Confirm removal?',
        error: {
          empty_domain: 'Please specify a domain name.',
          invalid_domain: 'The domain name you have entered is not valid.',
          duplicate_value: 'Duplicate value: {item}',
          invalid_email_domain: 'The domain/email you have entered is not valid.'
        }
      },
      change_password: {},
      quarantine_notification: {
        reset_dialog: {
          title: 'Reset Settings',
          message: 'Are you sure you want to reset the settings?'
        },
        buttons: {
          save: SAVE,
          reset: 'Reset',
          default_interval: {
            never: 'Never',
            scheduled: 'Scheduled'
          }
        },
        title: 'Quarantine Notification',
        disabled_warning: 'Changes to this setting have been disabled by your domain administrator.',
        default_interval: 'Default interval for user quarantine notifications',
        schedule_notification: 'Schedule notification intervals',
        schedule_notification_sub_title:
          'Notifications will be sent at any time within the hour block chosen. Click and drag to select.  Hold Shift while dragging to unselect.',
        allow_override: 'Changes to this setting have been disabled by your domain administrator'
      },
      sender_policies: {
        invalid_domain: 'Please enter a valid domain name',
        invalid_domain_title: 'Invalid domain',
        confirm_removal: 'Are you sure you want to delete the policy?',
        confirm_removal_title: 'Confirm removal',
        block: 'Block',
        exempt: 'Exempt',
        quarantine: 'Quarantine',
        warningHoverMessage: 'Your Organization has blocked this sender',
        buttons: {
          bulk_edit: 'Bulk Edit',
          add: 'Add',
          remove: 'Remove'
        },
        tableHeadings: {
          sender: 'Sender',
          policy: 'Policy',
          comment: 'Comment',
          modified: 'Modified',
          actions: 'Actions'
        },
        title: 'Sender Policy',
        sub_title: 'Specify whether to Block or Exempt messages coming from a specific email address or domain.',
        sender_policy_whitelist_notification_message:
          'Your organization has limited the ability to block and allow email addresses and domains. Sender policies with some conflict will have a warning symbol next to them.'
      },
      linked_accounts: {
        title: 'Linked Accounts',
        sub_title:
          'You can add additional email addresses you have in the same domain to forward quarantined email to this account.',
        verification_message:
          'An email was sent to {email} with instructions on how to verify this account. This account will not be linked until it is verified.',
        confirm_unlink: 'Are you sure you want to unlink the account {email}?',
        account: 'Account (Email Address)',
        add: 'Add',
        unlink: 'Unlink',
        actions: 'Actions',
        unlink_title: 'Confirm Unlink',
        invalid_params: 'Invalid parameters',
        email_sent: 'The email account {userId} is now linked with your account {email}.',
        verifying: 'Verifying linked account...',
        unable_to_link: 'Unable to link these accounts'
      }
    },
    message_log: {
      title: 'Message Log',
      outbound_quarantine_title: 'Outbound Quarantine',
      more_filters: 'More Filters',
      search_bar: {
        search: SEARCH,
        find_hint: 'Find messages, recipients, senders, and more...'
      },
      columns: {
        status: 'STATUS',
        from: 'FROM',
        to: 'TO',
        subject: 'SUBJECT',
        date: 'DATE',
        size: 'SIZE',
        reason: 'REASON',
        score: 'SCORE',
        details: 'DETAILS'
      },
      toolbar: {
        refresh: 'Refresh',
        deliver: 'Deliver',
        export: 'Export Table to CSV',
        categorize: 'Categorize',
        delete: 'Delete Message(s)',
        reject: 'Reject Message(s)',
        corp_email: 'Corporate Email',
        trans_email: 'Transactional Email',
        market_materials: 'Marketing Materials and Newsletters',
        mailing_lists: 'Mailing Lists',
        social_media: 'Social Media',
        other: 'Other',
        message_queue: 'Message Queue',
        deliver_dialog: {
          title: 'Deliver Message',
          text: 'This message will be delivered to the recipient. The sender or domain can be added to your allow list to deliver future messages. Optionally, report the email to help the Barracuda team better identify threats. ',
          learn_more_link: 'Learn more',
          deliver_only: 'Only deliver the message(s)',
          allow_list_sender: 'Deliver and add the sender(s) to your allow list',
          allow_list_domain: 'Deliver and add the domain(s) to your allow list',
          deliver_report: 'Deliver & Report',
          deliver: 'Deliver'
        },
        categorize_other_box_title: 'Specify the category',
        limit_special_chars: 'Characters allowed are Aa-Zz, 0-9, -_ and spaces',
        character_limit: 'Only 30 characters allowed',
        new_email: 'New Message'
      },
      from_column_menu: {
        show_name: 'Show name',
        show_email: 'Show email address',
        show_both: 'Show both'
      },
      message_detail_toolbar: {
        downloading: 'Downloading',
        incorrectly_allowed: 'Report as Incorrectly Delivered',
        incorrectly_blocked: 'Report as Incorrectly Blocked',
        menu: {
          download: 'Download Message',
          allow_deliver: 'Allow Sender and Deliver Message',
          add_email: 'Add Sender Email to Block List',
          add_domain: 'Add Sender Domain to Block List',
          delete: 'Delete',
          delete_message: 'Delete Message',
          reject_message: 'Reject Message',
          show_history: 'Show History',
          hide_history: 'Hide History',
          reply: 'Reply',
          reply_all: 'Reply All',
          forward: 'Forward',
          actions: 'More Actions',
          view_atp_reports: 'View ATP reports',
          close: 'Close'
        }
      },
      header_detail: {
        long_reason: 'Message was %s due to %s',
        short_reason: 'Message was %s',
        remediated_by_sentinel: 'Allowed: Remediated by Impersonation Protection at %s',
        remediated_by_forensics: 'Allowed: Remediated by Incident Response at %s',
        from: 'From:',
        to: 'To:',
        subject: 'Subject:',
        date: 'Date:',
        attachments: 'Attachments:',
        mid: 'Message ID:',
        ip: 'IP:',
        env_from: 'Envelope From:',
        recipients: 'Recipients:'
      },
      recipients_info: {
        recipients: 'Recipients',
        action: 'Action',
        reason: 'Reason',
        delivery_status: 'Delivery Status'
      },
      message_preview: {
        message_too_large: 'Message too large. Please download this message to view the source.',
        message_has_virus: 'Virus detected. This message cannot be displayed.',
        show_images_banner: 'Images in this message are not automatically shown.',
        show_images: 'Show Images',
        cant_view_blocked: 'Your administrator has disabled viewing and delivering of blocked messages.',
        cant_view_quarantined: 'Your administrator has disabled viewing and delivering of quarantined messages.',
        no_content: 'This message has no content, or content is not yet available.',
        atd_detected: 'This message has one or more attachments with advanced threats detected.',
        admin_disabled: 'This account has disabled the message view. Please contact the account admin to enable.',
        encrypted: 'Message content is encrypted.'
      },
      redelivery_queue: {
        redelivering: 'Redelivering...',
        buttons: {
          retry: 'Retry',
          close: CLOSE
        },
        title: 'Message Queue',
        sub_title:
          'The Message Queue displays messages designated for Delivery from the Message Log. Messages are automatically removed from the Queue in 12 hours.'
      },
      email_composer: {
        reply: 'Re',
        forward: 'Fw',
        from: 'From',
        to: 'To',
        cc: 'Cc',
        attachments: 'Attachments',
        new_email_title: 'New Message',
        subject: 'Subject',
        buttons: {
          cancel: CANCEL,
          send: 'Send'
        }
      },
      reporting_action_modal: {
        report_message: 'Reporting message',
        incorrectly_delivered: 'Report as Incorrectly delivered',
        incorrectly_blocked: 'Report as Incorrectly blocked',
        incorrectly_delivered_content:
          'This email has been reported for further review by the Barracuda team. Provide additional details to ensure messages like this are blocked in the future.',
        incorrectly_blocked_content:
          'This email has been reported for further review by the Barracuda team. Provide additional details to ensure messages like this are delivered in the future.',
        buttons: {
          close: CLOSE,
          why_should_be_blocked: 'TELL US WHY IT SHOULD BE BLOCKED',
          why_should_be_allowed: 'TELL US WHY IT SHOULD BE ALLOWED'
        }
      },
      preset_menu: {
        label: 'Date Presets',
        one_day: 'Last 1 Day',
        two_days: 'Last 2 Days',
        one_week: 'Last 1 Week',
        two_weeks: 'Last 2 Weeks',
        one_month: 'Last 1 Month',
        custom: 'Custom'
      },
      domain_menu: {
        label: DOMAINS
      },
      action: {
        label: 'Action Taken',
        any: 'Any',
        allowed: 'Allowed',
        blocked: 'Blocked',
        quarantined: 'Quarantined',
        encrypted: 'Encrypted',
        deferred: 'Deferred',
        remediated: 'Remediated',
        remediated_by_sentinel: 'Allowed: Remediated by Impersonation Protection at {datetime}',
        remediated_by_forensics: 'Allowed: Remediated by Incident Response at {datetime}',
        email_continuity: 'Email Continuity'
      },
      action_for_some: {
        allowed: 'Allowed for some',
        blocked: 'Blocked for some',
        quarantined: 'Quarantined for some',
        encrypted: 'Encrypted for some',
        deferred: 'Deferred for some'
      },
      status: {
        label: 'Delivery Status',
        any: 'Any',
        delivered: 'Delivered',
        delivered_partial: 'Delivered for some',
        spooled: 'Spooled',
        not_delivered: 'Not Delivered',
        deferred: 'Deferred',
        rejected: 'Rejected'
      },
      encryption: {
        inbound_outbound_encrypted: 'Inbound Encryption + Outbound Encryption',
        inbound_encrypted: 'Inbound Encryption Only',
        outbound_encrypted: 'Outbound Encryption Only',
        encrypted_for_some: 'Inbound Encryption + Outbound Encryption for some recipients'
      },
      reason: {
        label: 'Reason',
        any: 'Any',
        spf: 'Sender Policy Framework (SPF)',
        bafi: 'Anti-Fraud',
        barracuda_email_blacklist: 'Sender Email Address',
        av: 'Antivirus',
        bbl: 'Barracuda Reputation',
        brts: 'Barracuda Real-Time System (BRTS)',
        content_url: 'Content URL',
        content_protected: 'Content Protected',
        intent: 'Intent Analysis',
        image_analysis: 'Image Analysis',
        rbl: 'Realtime Block List',
        content_subject: 'Subject Content',
        content_header: 'Header Content',
        content_body: 'Body Content',
        predefined_filter_exemption: 'Predefined Filter Exemptions',
        predefined_content_sender: 'Predefined From Address',
        content_attachment: 'Attachment Content',
        content_sender: 'From Address',
        content_recipient: 'To/CC Address',
        invalid_recipient: 'Invalid Recipient',
        dest_invalid_recipient: 'Invalid Recipient',
        dkim: 'DomainKeys (DKIM)',
        dmarc: 'DMARC',
        encryption_exemption: 'Encryption Exception',
        mark_spam: 'Sent to Spam Categorization',
        no_prt: 'No PTR Record',
        no_spf: 'No SPF Record',
        password_protected_pdf: 'Password Protected PDF Filtering',
        patd_scan_pending: 'Pending Scan',
        phishline: 'Security Awareness Training',
        predefined_content_subject: 'Predefined Subject Content',
        predefined_content_header: 'Predefined Header Content',
        predefined_content_body: 'Predefined Body Content',
        predefined_content_attachment: 'Predefined Attachment Content',
        predefined_content_recipient: 'Predefined To/CC Address',
        rate_control: 'Rate Control',
        sender: 'Sender Policies',
        ip: 'IP Address Policies',
        tls_required: 'TLS Required',
        inbound_tls_required: 'Inbound TLS Required',
        attachment_filter: 'Attachment Filter',
        message_loop: 'Possible Mail Loop',
        message_size: 'Message Too Large',
        bulk_email: 'Bulk Email',
        office_macros: 'Office Macros',
        predefined_filter_exception: 'Predefined Filter Exceptions',
        emailcat: 'Email Categorization',
        system_sender: 'System Sender Policies',
        recipient: 'Recipient',
        recipient_limit: 'Recipients List',
        remediated_by_sentinel: 'Remediated by Impersonation Protection',
        remediated_by_forensics: 'Remediated by Incident Response',
        atd_exemption: 'ATP Exempt',
        atd_full: 'Advanced Threat Protection',
        atd: 'Advanced Threat Protection',
        atd_subscribed: 'Advanced Threat Detection',
        atd_quarantine: 'ATP Quarantined',
        batd_scan_pending: 'Pending Scan',
        service_error_batd: 'ATP Scan Inconclusive',
        ui_delivered: 'UI Delivered',
        sender_disconnect: 'Message Delivery Interrupted',
        sender_quit: 'Message Incomplete',
        sender_rset: 'Message Incomplete',
        service_error_av: 'AV Service Unavailable',
        service_error_cs: 'Cloudscan Service Unavailable',
        sender_spoof: 'Sender Spoof Protection',
        score: 'Score',
        suspicious: 'Suspicious',
        suspended: 'Account Suspended',
        content_protected_msdoc: 'Office Protected',
        taxonomy_blackmail: 'Extortion',
        taxonomy_spam: 'Spam',
        taxonomy_becspoofing: 'Impersonation',
        taxonomy_conversationhijacking: 'Conversation Hijacking',
        taxonomy_phishing: 'Phishing',
        malformed: 'Malformed',
        language: 'Language Policies',
        geoip: 'GeoIP Policies',
        'system-sender': 'System Sender Policies',
        no_ptr: 'No PTR record',
        ndr: 'Outbound NDR Policy'
      },
      export: {
        message_id: 'Message ID',
        from: 'From',
        to: 'To',
        subject: 'Subject',
        date: 'Date',
        size: 'Size',
        action: 'Action',
        reason: 'Reason',
        score: 'Score'
      },
      error: {
        no_email_domain: 'The message log is unavailable because you have no email domains'
      },
      no_records_available: 'No records available',
      message_direction_menu: {
        inbound: 'Inbound',
        outbound: 'Outbound',
        label: 'Direction'
      },
      find_similar_messages: 'Search for similar messages',
      pager_count: {
        items: 'items',
        of: 'of'
      }
    },
    message_detail: {
      title: 'Message Detail',
      preview: 'Preview',
      source: 'Source'
    },
    splitter_orientation: {
      none: 'No Split',
      horizontal: 'Horizontal Split',
      vertical: 'Vertical Split'
    },
    navbar: {
      add: 'Add',
      changes_saved: 'Changes saved',
      legacy_ui: 'Classic UI'
    },
    logoutmenu: {
      serial_number: 'Serial number',
      expiration: 'Expiration',
      sign_out: 'Sign Out',
      language_selector: 'Language: ',
      language_title: 'Select preferred language',
      review_license: 'Review licenses'
    },
    email_details: {
      email_from: 'Email from {sender} on {date}',
      recipients: 'Recipients',
      sender: SENDER,
      date: 'Date',
      subject: SUBJECT,
      headers: HEADERS,
      value: 'Value',
      type: 'Type',
      copied: 'Copied',
      copy_to_clipboard: 'Copy to clipboard',
      tabs: {
        email: EMAIL,
        headers: HEADERS,
        threat_details: 'Threat Details'
      },
      threats: {
        dmarc: 'DMARC Compliance',
        spf: 'SPF IP Check',
        dkim: 'DKIM Alignment'
      }
    },
    sidemenu: {
      logout: 'Log out',
      profile: 'Profile',
      language_selector: 'Language: {language}',
      manage_profile: 'Manage profile',
      review_licenses: 'Review Licenses',
      message_log: MESSAGE_LOG,
      settings: SETTINGS,
      support: SUPPORT,
      domains: DOMAINS,
      overview: OVERVIEW,
      inbound_settings: INBOUND_SETTINGS,
      outbound_settings: OUTBOUND_SETTINGS,
      atp_settings: ATP_SETTINGS,
      users: USERS,
      report_list: REPORTS,
      account_management: ACCOUNT_MANAGEMENT,
      settings_sender_policy: SETTINGS,
      bcc_admin: 'Barracuda Cloud Control',
      switch_to_old_ui: 'Switch to old UI',
      new_interface: 'New product interface',
      your_apps: 'Your apps',
      email_log: 'Email log',
      cookie_preferences: 'Cookie preferences'
    },
    change_password_page: {
      change_password: 'Change Password',
      old_password: 'Current Password',
      new_password: 'New Password',
      confirm_new_password: 'Confirm New Password',
      change_password_title: 'Confirm Change Password',
      change_password_confirmation: 'Are you sure you want to change the password?'
    },
    scheduler: {
      mon: 'Monday',
      tue: 'Tuesday',
      wed: 'Wednesday',
      thu: 'Thursday',
      fri: 'Friday',
      sat: 'Saturday',
      sun: 'Sunday',
      short_mon: 'Mon',
      short_tue: 'Tue',
      short_wed: 'Wed',
      short_thu: 'Thu',
      short_fri: 'Fri',
      short_sat: 'Sat',
      short_sun: 'Sun'
    },
    snackbar: {
      new_report_save_success: '"%s" report is successfully saved',
      new_report_save_failed: 'Failed to save "%s" report',
      load_report_failed: 'Failed to load "%s" report',
      set_pinned_report_id_success: 'Successfully set pinned report: "%s',
      set_pinned_report_id_failed: 'Failed to pin report: "%s"',
      download_attachment_failed: 'Failed to download attachment',
      download_message_failed: 'Failed to download message',
      bad_date_range: 'Start date/time must be earlier than End date/time',
      post_policy_success: 'Sender policy was successfully added',
      post_policy_failure: 'Failed to add sender policy',
      post_policy_duplicate_failure: 'Duplicate entry exists',
      put_bulk_edit_success: 'Bulk edit was successfully saved',
      put_bulk_edit_failure: 'Failed to save bulk edit: %s',
      put_quarantine_notification_success: 'Quarantine notification settings were successfully saved',
      put_quarantine_notification_failure: 'Failed to save quarantine notification settings',
      delete_policy_success: 'Sender policy was successfully deleted',
      delete_policy_failure: 'Failed to delete sender policy',
      password_success: 'Password was successfully set',
      password_failure: 'Failed to set password',
      reset_password_success: 'Password was successfully reset',
      reset_password_failure: 'Failed to reset password',
      get_message_source_failed: 'Failed to get message source',
      local_storage_quota_exceeded: "Browser's local storage ran out of space",
      get_redeliver_message_failure: 'Failed to get delivery queue messages',
      post_block_sender_success: 'Added "%s" to block list',
      post_block_sender_failed: 'Failed to add sender or domain to block list',
      post_allow_sender_success: '%d message(s) allow listed and sent to the delivery queue',
      post_allow_sender_partial:
        '%d message(s) allow listed and sent to the delivery queue, %v message(s) were invalid and were not allow listed or sent to the delivery queue',
      post_allow_sender_invalid: '%d message(s) were invalid and were not allow listed or sent to the delivery queue',
      post_allow_sender_failed: 'Failed to add sender or domain to allow list',
      post_allow_sender_admin_forbidden: 'Action forbidden by admin policy, please contact your admin',
      redeliver_message_partial:
        '%d message(s) sent to the delivery queue, %v message(s) were invalid and were not sent to the delivery queue',
      redeliver_message_success: '%d message(s) sent to the delivery queue',
      redeliver_message_invalid: '%d message(s) were invalid and were not sent to the delivery queue',
      redeliver_message_failed: 'An error occurred. 0 messages sent to the delivery queue',
      redeliver_message_admin_forbidden: 'Action forbidden by admin policy, please contact your admin',
      deliver_message_partial:
        '%d message(s) sent to the delivery queue, %v message(s) were invalid and were not sent to the delivery queue',
      deliver_message_success: '%d message(s) sent to the delivery queue',
      deliver_message_invalid: '%d message(s) were invalid and were not sent to the delivery queue',
      deliver_message_failed: 'An error occurred. 0 messages sent to the delivery queue',
      deliver_message_admin_forbidden: 'Action forbidden by admin policy, please contact your admin',
      post_redeliver_message_success: '%d message(s) sent to the delivery queue',
      post_redeliver_message_failure: 'An error occurred. 0 messages sent to the delivery queue',
      post_redeliver_message_admin_forbidden: 'Action forbidden by admin policy, please contact your admin',
      post_new_email_success: 'The email was successfully sent',
      post_new_email_failure: 'Failed to send the email',
      post_recategorize_success: '%d message(s) recategorized',
      post_recategorize_failure: 'Failed to recategorize message(s)',
      post_add_linked_account_success: 'Linked account was successfully added',
      post_add_linked_account_failure: 'Failed to add linked account',
      post_add_linked_account_not_same_domain_failure: 'The linked account must be on the same domain as %s, etc',
      post_add_linked_account_existed_failure:
        'This user already exists. Please choose another email address to add as an alias.',
      post_add_linked_account_already_linked_failure: 'The email address %s is already linked to %s',
      post_delete_linked_account_success: 'Email address was successfully unlinked',
      post_delete_linked_account_failure: 'Failed to unlink email address',
      error_password_char_limit: 'Password must be less than 100 characters',
      error_new_password_not_same: 'New Password cannot be the same as Old Password',
      error_confirm_password_is_same: 'New Password and Confirm New Password must be the same',
      post_fp_report_success:
        'Message marked as not spam. Please disable ad blockers to complete our email efficacy feedback form when you report a message.',
      post_fp_report_warning:
        'Message exceeds size limit. Please disable ad blockers to complete our email efficacy feedback form when you report a message.',
      post_fp_report_failure: 'Failed to mark the message as not spam',
      post_fn_report_success:
        'Message marked as spam. Please disable ad blockers to complete our email efficacy feedback form when you report a message.',
      post_fn_report_warning:
        'Message exceeds size limit. Please disable ad blockers to complete our email efficacy feedback form when you report a message.',
      post_fn_report_failure: 'Failed to mark the message as spam',
      delete_message_success: '%d message(s) successfully deleted',
      delete_message_failure: 'Message(s) could not be deleted',
      delete_message_partial_success: '%d message(s) successfully deleted and %d message(s) could not be deleted',
      stop_delivery_reasons: '0 Messages delivered. Cannot deliver due to "%s"',
      stop_delivery_admin: 'Delivery of %s messages has been disabled by your administrator',
      message_detail_missing: 'Message detail is missing. 0 messages delivered',
      detect_email_server_failure: 'Failed to detect email server',
      verify_email_server_failure: 'Failed to verify email server',
      wizard_save_error: 'There was an error while saving, try again',
      download_message_success: 'Message successfully downloaded',
      download_message_failure: 'Message could not be downloaded',
      download_attachment_success: 'Attachment successfully downloaded',
      download_attachment_failure: 'Attachment could not be downloaded',
      post_reject_messages_success: '%d message(s) rejected',
      post_reject_messages_partial_success: 'Rejected %d messages; Failed to reject %d message(s)',
      post_reject_messages_failed: 'Failed to reject message(s)',
      success_changing_settings: 'Your setting changes have been saved.',
      error_changing_settings: 'Error saving changes.',
      add_domain_success: 'Domain was successfully added',
      add_domain_transfer_required: 'Domain exists in other account, domain transfer is required',
      add_domain_failure: 'Failed to add the domain',
      delete_domain_success: 'Domain was successfully deleted',
      delete_domain_failure: 'Failed to delete the domain',
      delete_domain_failure_not_found: 'The domain to be deleted was not found',
      post_syslog_success: 'Syslog was successfully added',
      post_syslog_failure: 'Failed to add the syslog',
      test_syslog_success: 'Server Status: Port Reachable',
      test_syslog_failure: 'Server Status: Port Unreachable',
      delete_syslog_success: 'Syslog was successfully deleted',
      delete_syslog_failure: 'Failed to delete the syslog',
      update_account_user_role_success: 'Roles are successfully updated of user: "%s"',
      add_update_account_users_success: 'Successfully updated %s account user(s)',
      add_update_account_users_failed: 'Failed to update account user(s)',
      get_email_continuity_settings_failed: 'Failed to load Email continuity settings',
      update_email_continuity_settings_success: 'Successfully updated Email continuity settings',
      update_email_continuity_settings_failed: 'Failed to update Email continuity settings',
      update_quarantine_notifications_settings_success: 'Successfully updated Quarantine notifications settings',
      update_quarantine_notifications_settings_failed: 'Failed to update Quarantine notifications settings',
      update_policies_settings_success: 'Successfully update Policies settings',
      update_policies_settings_failed: 'Failed to update Policies settings',
      delete_account_user_success: 'User: "%s" is successfully deleted',
      delete_account_users_success: 'Successfully deleted %s account users',
      login_as_user_failed: 'Unable to login as "%s"',
      account_switch_failed: 'Unable to switch to "%s" account',
      add_domain_error_duplicate: 'This domain name has already been added.',
      add_domain_error_exist_in_other_account: 'This domain already added and verified by another account',
      add_domain_error_alias: 'Domain is alias to %s. SSDM will remain blocked until the relationship is removed.',
      add_domain_error_quarantine_notifier: 'Failed to create Quarantine Notifier settings',
      add_domain_error_blacklisted_domain_name: 'This domain name cannot be added',
      delete_domain_failure_quarantine_notifier:
        'Domain has been deleted, but deleting related Quarantine Notifier settings failed',
      manage_domain_failure: 'Switching to the selected domain failed',
      manage_domain_failure_forbidden: "You don't have sufficient privileges to manage the selected domain",
      edit_domain_failure_forbidden: "You don't have sufficient privileges to edit the selected domain",
      get_domain_failure: 'An error occurred while fetching the domain data',
      wildcard_email_policy:
        'Malformed emails detected, wildcards are not supported. For example use customer.com instead of *@customer.com',
      verify_domain_success: {
        verified_now: 'Thank you, the domain %s is now verified.',
        email_sent: 'An email containing verification instructions has been sent to %s.',
        cpl_verification_started: 'Verification for %s has started. This may take a few minutes.'
      },
      verify_domain_failure: {
        mx_error: 'Unable to verify MX records for the domain',
        no_tech_email: 'No email address found in the WHOIS information for this domain. Please try another method.',
        cname_error:
          "Currently, the correct CNAME record for your domain cannot be found. Please verify that the entry has been added into your DNS records. Note that any changes could take up to 24 hours to propagate depending on your ISP's settings.",
        invalid_or_expired: 'Invalid domain or expired verification code',
        verified_by_other_account:
          'This domain has already been added and verified by a different account. If this is your domain, please contact Customer Services for further assistance.',
        already_verified: 'The domain %s is already verified.',
        email_send_failure: 'Failed to send email message to %s',
        tech_email_not_found: 'Could not find technical contact email address for domain %s',
        cpl_verification_failed: 'Domain verification failed, please call Barracuda Networks Support.',
        default: 'Unable to verify the domain'
      },
      add_mail_server_success: 'Mail server has been saved',
      add_mail_server_failure: {
        default: 'Failed to save the mail server',
        duplicate: 'Duplicate mail server exists.'
      },
      remove_mail_server_success: 'Mail server has been removed',
      remove_mail_server_failure: {
        default: 'Failed to remove the mail server',
        not_found: 'Mail server does not exist.'
      },
      update_mail_server_success: 'Mail server has been updated',
      update_mail_server_failure: {
        default: 'Failed to update the mail server',
        not_found: 'Mail server does not exist.',
        duplicate: 'Duplicate mail server exists.'
      },
      test_mail_server_success: 'Message successfully delivered to %s@%s',
      test_mail_server_failure: {
        default: 'Message could not be delivered.',
        not_found: 'Mail server does not exist.'
      },
      save_domain_settings_success: 'Domain settings have been saved successfully',
      save_domain_settings_failure: 'Failed to save the domain settings',
      error_subnet_too_large: 'Specified subnet range is too large. Please divide into multiple /24 subnets.',
      error_new_entry_single_ip: 'New entries may only be single IP numbers',
      error_is_not_verified_domain: 'Domain name must be of a verified domain.',
      error_already_specified: 'The specified subnet conflicts with a subnet from another account',
      error_potentially_shared_infrastructure:
        'The IP address/range you selected is part of a range that has been marked as potentially shared infrastructure. You can not assign that to any specific domain.',
      error_no_reverse_dns: 'The IP address you provided lacks reverse DNS',
      error_conflict_subnet: 'The specified subnet conflicts with the subnet: %s / %s',
      row_error_subnet_too_large:
        'Error on row %s (Specified subnet range is too large. Please divide into multiple /24 subnets.)',
      row_error_new_entry_single_ip: 'Error on row %s (New entries may only be single IP numbers.)',
      row_error_is_not_verified_domain: 'Error on row %s (Domain name must be of a verified domain.)',
      row_error_already_specified:
        'Error on row %s (The specified subnet conflicts with a subnet from another account.)',
      row_error_potentially_shared_infrastructure:
        'Error on row %s (The IP address/range you selected is part of a range that has been marked as potentially shared infrastructure. You can not assign that to any specific domain.)',
      row_error_no_reverse_dns: 'Error on row %s (The IP address you provided lacks reverse DNS)',
      row_error_conflict_subnet: 'Error on row %s (The specified subnet conflicts with the subnet: %s / %s)',
      save_domain_settings_unconfirmed_warning: 'Rejected update due to unconfirmed warnings',
      lookup_azure_ad_user: {
        success: 'Email address present in Azure AD account',
        failure: 'Could not find email address in Azure AD account',
        signed_url_error: 'Failed to query the Azure AD account'
      },
      encryption_confirm_validation_success: 'Domain has been validated successfully.',
      encryption_confirm_validation_failure: 'Failed to validate domain, please try again.',
      encryption_logo_remove_success: 'Logo has been removed successfully.',
      encryption_validation_remove_success: 'Validation settings have been removed.',
      encryption_cname_validation_success:
        'Please create the subdomain: %s and point the record to "encrypt.barracudanetworks.com". After creating this record, click Confirm Validation.',
      encryption_post_validation_success:
        'An email has been sent to %s. After following the link in this email, click Confirm Validation to complete the domain verification process with the Barracuda Email Encryption Service.',
      encryption_validation_invalid: 'Please start by validating with CNAME or Postmaster',
      encryption_failure: 'The request could not be made at this time.',
      ldap_get_sync_stats_failure: 'Failed to get LDAP sync stats',
      ldap_test_success: 'Successfully connected to LDAP server',
      ldap_test_failure: 'Failed to connect to LDAP server',
      ldap_serial_reset_success: 'Highest seen serial has been reset to 0',
      ldap_serial_reset_failure: 'Failed to reset LDAP highest seen serial',
      ldap_sync_success: 'LDAP users synchronized. %s user(s) updated. %s user(s) added. %s user(s) deleted.',
      ldap_sync_failure: 'LDAP sync failed',
      edit_domain_form_has_errors: 'There are errors in the form. Please correct them and save again.',
      reset_domain_settings_success: 'Successfully reset domain settings back to global policies',
      reset_domain_settings_failure: 'Unable to change settings',
      save_and_apply_config: {
        backup_failure: 'Operation failed. Please try again later.',
        restore_success: 'Configuration settings were successfully applied.',
        restore_failure: 'Invalid or expired token. Failed to restore.'
      },
      invalid_domain: 'Invalid domain name: %s',
      duplicate_email: 'Duplicate email: %s',
      user_not_exist: 'User does not exist: %s',
      invalid_email: 'Invalid email: %s',
      verify_domain_transfer_completed: 'The domain %s was successfully transferred.',
      verify_domain_transfer_success: 'The domain transfer has been started',
      verify_domain_transfer_failure: 'Failed to verify the domain',
      delete_domain_move_success: 'The domain %s was successfully removed.',
      delete_domain_move_failure: 'Failed to remove the domain',
      delete_domain_move_failure_not_found: 'The domain was not found.',
      delete_domain_move_failure_unauthorized: "You don't have sufficient privileges to remove the selected domain",
      schedule_report_success: 'Schedule created',
      schedule_report_updated_success: 'Schedule updated your changes have successfully saved.',
      schedule_report_deleted_success: 'Schedule deleted',
      error_parsing_settings: 'Invalid JSON formatted settings',
      error_download_report: 'Failed to download report'
    },
    data_tables: {
      message_log: {
        select: 'Select',
        action: 'Action',
        delivery: 'Delivery',
        from: 'From',
        to: 'To',
        subject: 'Subject',
        date: 'Date',
        size: 'Size',
        encryption: 'Encryption',
        attachment: 'Attachment',
        reason: 'Reason',
        score: 'Score'
      },
      audit_log: {
        timestamp: 'Date',
        action: 'Event Type',
        description: 'Description',
        actor: 'User',
        ip: 'IP address'
      },
      domains: {
        domain_name: 'Domain Name',
        status: 'Status',
        aliases: 'Aliases',
        recipients: 'Recipients (Last 30 Days)',
        mail_servers: 'Mail Servers',
        settings: 'Settings',
        domain_options: 'Domain Options'
      },
      atp_log: {
        status: 'Status',
        time: 'Time',
        from: 'From',
        to: 'To',
        subject: 'Subject',
        file_name: 'File Name',
        file_type: 'File Type',
        report: 'Report',
        view_report: 'View Report',
        no_records_available: 'No records available'
      }
    },
    atp_log: {
      title: 'ATP log',
      domain: 'Domain',
      infected_attachments: 'Infected attachments',
      suspicious_attachments: 'Suspicious attachments',
      last_30_days: 'In the last 30 days',
      status: 'Status',
      time: 'Time',
      file_type: 'File Type',
      export_button: 'Download CSV',
      search_placeholder: 'Search sender, recipient, subject, or file name',
      all_domains: 'All domains',
      all: 'All File Types',
      filters: {
        all: 'All',
        clean: 'Clean',
        suspicious: 'Suspicious',
        virus: 'Infected',
        30: 'Last 30 Days',
        7: 'Last 7 Days',
        1: 'Last 24 Hours'
      },
      logStatuses: {
        all: 'All',
        infected: 'Infected',
        clean: 'Clean',
        error: 'Error',
        suspicious: 'Suspicious',
        future: 'Scanning...'
      },
      export: {
        status: 'Status',
        origin: 'Origin',
        fileName: 'File Name',
        fileType: 'File Type',
        messageId: 'Message ID',
        from: 'From',
        to: 'To',
        time: 'Time'
      },
      view_reports_modal: {
        title: 'Email Delivery Warning',
        content:
          'This message has the following attachments, one or more of which has been detected to have advanced threats. Review the report for each infected attachment. If you determine you want to deliver the message, review and accept the disclaimer, and click Deliver Message.',
        cancel: CANCEL,
        deliver: 'Deliver',
        checkbox_label:
          'I accept that Barracuda Networks assumes no risk associated with threats my organization may sustain during or in conjunction with the delivery of this message and its associated attachments.',
        ui_loading: 'Loading attachment data...',
        no_atp_attachment: 'No ATP attachment'
      }
    },
    audit_log: {
      title: 'Audit Log',
      status: 'Status',
      time: 'Time',
      file_type: 'File Type',
      exportButton: 'Export CSV',
      search_placeholder: SEARCH,
      start_date: 'Start Date',
      end_date: 'End Date',
      filter: {
        title: 'Filter',
        filter: 'Filter',
        event_type: 'Event type',
        apply: 'Apply',
        clear: 'Clear'
      },
      export: {
        to: 'To',
        time: 'TIME',
        ipAddress: 'IP address',
        userName: 'User',
        description: 'Description',
        eventType: 'Event Type',
        formattedDate: 'Date'
      },
      action: {
        select_all: 'Select all',
        move: 'Move',
        delete: 'Delete',
        change: 'Classified',
        login: 'Sign in',
        logout: 'Sign out',
        log_action: 'Log action',
        create: 'Create',
        delete_all: 'Delete all',
        reset: 'Reset',
        enable: 'Enable',
        disable: 'Disable',
        suspend: 'Suspend',
        notify: 'Notify',
        migrate_cpl: 'Migrate CPL',
        restore: 'Restore',
        login_failure: 'Sign in failure'
      },
      affected: {
        unavailable: 'Unavailable',
        details_unavailable: 'Details Unavailable',
        require_password_for_quarantine_links: 'Required password for quarantine links',
        allow_quarantine_notification_interval_change: 'Allow users to specify interval',
        enable_quarantine: 'Quarantine Messages',
        sender_policies: 'Sender Policies',
        quarantine_notification_interval: 'Quarantine Notification Interval',
        created: 'User Creation Time',
        sub_expires: 'Subscription Expiry',
        sub_start_date: 'Subscription Start Date',
        visited: 'Visited',
        password: 'Password',
        smtp_servers: 'Mail Servers',
        message_content_filters: 'Message Content Filter',
        ip_policies: 'IP Address Policies',
        geoip_policies: 'GeoIP Policies',
        spooling: 'Spooling',
        spf_hardfail: 'Sender Policy Framework (SPF) Hard Fail',
        spf_softfail: 'Sender Policy Framework (SPF) Soft Fail',
        cname_token: 'CNAME token',
        directory_services: 'Directory Services',
        dkim: 'DomainKeys Identified Mail (DKIM) Action',
        azure_ad: 'Azure AD',
        sender_spoof: 'Sender Spoof Protection',
        user_auto_add: 'Automatically Add Users',
        fastspam_scores: 'Cloudscan Scoring',
        encryption_passtoken: 'Encryption Validation Passtoken',
        encryption_vtype: 'Encryption Validation Type',
        ldap_auth: 'LDAP authentication config',
        ldap_sync: 'LDAP sync config',
        force_tls: 'SMTP over TLS',
        srs: 'Sender Rewriting Scheme (SRS)',
        ptr: 'Block on No PTR Records',
        bafi: 'Anti-Fraud Intelligence',
        redirector_content_action: 'Content Intent',
        atd: 'Advanced Threat Protection',
        intent: 'Intent Analysis',
        brts: 'Barracuda Real-Time System (BRTS)',
        brbl: 'Barracuda Reputation Block List',
        fastspam_enabled: 'Cloudscan',
        encryption_verification: 'Encryption Verification',
        vscan: 'Virus Scanning',
        ext_sender_warning: 'External Sender Warning',
        linkprotect: 'Link Protection',
        default_user_exempt_enabled: 'Allow users to exempt senders',
        status: 'Subscription Status',
        typosquat: 'Typosquatting Protection',
        outbound_message_content_filters: 'Outbound Message Content Filter',
        linked_domain_policy: 'Intent Domain Policies',
        dmarc: 'Domain Based Message Authentication (DMARC)',
        dkim_exempt: 'DKIM Exempt',
        atd_notify: 'ATP - Notify Admin',
        user_policies: 'Recipient Policies',
        ldap: 'LDAP Sync Settings',
        encryption_confirmed: 'Encryption Validation Confirmed',
        outbound_predefined_message_content_filters: 'Outbound Predefined Filters',
        spf_exempt: 'SPF Exemptions by IP Address',
        spf_domain_exempt: 'SPF Exemptions by Domain',
        language_policies: 'Language Policies',
        atd_exempt_email: 'ATP - Exemptions by Email Address / Domains',
        default_timezone: 'Default Timezone',
        bulk_email_detection: 'Bulk Email Detection',
        attachment_filter_policy: 'Attachment Filter',
        default_unmanaged_scan_policy: 'Default policy for unmanaged users',
        emailcat_marketing: 'Marketing Materials and Newsletters',
        default_user_deliver_enabled: 'Allow end users to view and deliver blocked messages',
        default_user_quar_deliver_enabled: 'Allow end users to view and deliver quarantined messages',
        emailcat_listserver: 'Mailing Lists categorization',
        emailcat_socialmedia: 'Social Media categorization',
        emailcat_corporate: 'Corporate Email categorization',
        emailcat_transactional: 'Transactional Email categorization',
        emergency_inbox_autoenabled: 'Email Continuity',
        outbound_tls_domains: 'Outbound TLS Domains',
        default_managed_scan_policy: 'Default policy for managed users',
        trusted_forwarders: 'Trusted Forwarders',
        managed_domains: 'Domains Managed by User',
        default_user_block_enabled: 'Allow users to block senders',
        deleted: 'Domain Deleted',
        rate_control_exemptions: 'Rate Control Exemptions',
        saved_searches: 'Saved Searches',
        rbls: 'Reputation Black Lists (RBLs)',
        atd_exempt_ip: 'ATP - Exemptions by Sender IP Address',
        dmarc_exempt: 'DMARC Exempt',
        ptr_exempt: 'Missing PTR Exemptions',
        outbound_spam_notification_address: 'Outbound Spam Notification Address',
        reject_notification_address: 'Reject Notification Address',
        rate_control: 'Rate Control',
        alias_of: 'Domain Alias',
        helpdesk_domains: 'User Helpdesk Domains',
        outbound_tagline: 'Outbound Tagline/Footer text',
        outbound_tagline_enabled: 'Append Tagline',
        outbound_attachment_filter_policy: 'Outbound Attachment Filter',
        atd_admin_emails: 'ATP Notification Email',
        disable_account_rate_control: 'Internal Rate Control',
        sender_notification_address: 'Quarantine Notification Address',
        sender_notification_enable: 'Quarantine Sender Notification',
        reject_notification_subject: 'Reject Notification Subject',
        reject_notification_template: 'Reject Notification Template',
        password_msoffice_filtering: 'Password Protected Microsoft Office Documents',
        password_archive_filtering: 'Password Protected Archive Filtering',
        outbound_password_archive_filtering: 'Outbound Password Proteted Archive Filtering',
        password_pdf_filtering: 'Password Protected PDF',
        outbound_password_pdf_filtering: 'Outbound Password Protected PDF',
        outbound_predefined_message_content_filter_exceptions: 'Outbound Predefined Filter Exceptions',
        outbound_password_msoffice_filtering: 'Outbound Password Protected Microsoft Office Documents',
        sender_notification_subject: 'Quarantine Notification Subject',
        sender_notification_template: 'Quarantine Notification Template',
        support_access_enabled: 'Support Access',
        default_scan_policy: 'Default Recipient Policy',
        message_body_access_enabled: 'Partner Access to Messages',
        dismissed_notifications: 'Notification Dismissed',
        sub_override: 'Subscription Overridden by Barracuda Networks',
        brts_send_evidence: 'Send suspicious message content to Barracuda Central for analysis',
        outbound_quarantine_start: 'Notification Start Time',
        outbound_quarantine_email: 'Notification Address',
        outbound_quarantine_interval: 'Outbound Quarantine Notification Interval',
        custom_interval: 'Outbound Quarantine Notification Interval',
        search_logs_all_domains: '(Internal Setting) Enable search message logs across all domains',
        azure_ad_ui: '(Internal Setting) Enable Azure AD settings',
        domain_move: '(Internal Setting) Enable self-service domain move',
        trial_expiration_banner: '(Internal Setting) Enable trial expiration banner',
        greylist_exempt: '(Internal Setting) Disable greylist (suspicious) deferrals for account',
        use_primary_smarthost: '(Internal Setting) Use Primary Smarthost',
        auditlog: '(Internal Setting) Enable Audit Log',
        click_protection: '(Internal Setting) Enable click protection settings',
        config_copy: '(Internal Setting) Enable Account Settings Backup',
        dmarc_ui: '(Internal Setting) Enable DMARC settings',
        inbound_tagline: '(Internal Setting) Inbound Email Tagline',
        noblock_sender_response: '(Internal Setting) Enable Silent Block of Emails',
        retention: '(Internal Setting) Email retention period',
        outbound_use_email_domain: '(Internal Setting) Use email domain for outbound messages',
        outbound_ndr_policy: '(Internal Setting) Outbound NDR Policy',
        spoofing_enabled: '(Internal Setting) Spoofing Enabled',
        max_message_size: '(Internal Setting) Max Message Size',
        athena_stats: '(Internal Setting) Enable athena recipient count',
        disable_shared_ip_ranges: '(Internal Setting) Hosted Outbound Relay – EG. O365, Google Apps, ...',
        outbound_ip_partition_id: '(Internal Setting) Outbound Partition Type',
        skip_subdomains: '(Internal Setting) Reject mail to child domains',
        outbound_rate_control_block: '(Internal Setting) Outbound User Rate Control Block',
        outbound_rate_control: '(Internal Setting) Unmanaged User Rate Control Per Domain',
        outbound_per_domain_rate_limit: '(Internal Setting) Managed User Rate Control Per User',
        admins_msg_body_access: '(Internal Setting) Enable admins to be able to view email content and attachments',
        disable_outbound_multilevel_intent_content_check:
          '(Internal Setting) Enable admins to turn off outbound multilevel-intent checking of web page content',
        outbound_per_user_rate_limit: '(Internal Setting) Outbound User Rate Control Exemptions',
        type: '(Internal Setting) Domain Type (ESS/Hybrid)',
        end_user_react_ui: '(Internal Setting) End-User React UI',
        react_end_users: '(Internal Setting) End-User React UI',
        inline_deployment: 'Inline Deployment',

        // NOTE: These Quarantine Notifier Settings need to be in camel-case
        allowOverride: 'Allow users to specify interval',
        inboundSchedule: 'Quarantine Notification Interval',
        customInboundSchedule: 'Schedule Notification Intervals',
        customOutboundSchedule: 'Schedule notification intervals',
        outboundEmailIds: 'Notification Address',
        outboundSchedule: 'Outbound Quarantine Notification Interval',
        allowWhitelist: 'Allow users to exempt senders',

        // Strings with params
        fmt_domain_moved: 'Domain %s moved to %s',
        fmt_domain_from: 'Domain %s moved to this account from %s',
        fmt_changed: '%s changed',
        fmt_changed_for_domain: '%s changed for domain %s',
        fmt_user_deleted: 'User %s deleted',
        fmt_outbound_ip_deleted: 'Outbound IP %s deleted',
        fmt_deleted_for_domain: '%s deleted for domain %s',
        fmt_domain_deleted: 'Domain %s deleted',
        fmt_deleted: '%s deleted',
        fmt_user_logged_in: 'User logged in successfully',
        fmt_logged_in_domain: 'User logged in to domain %s management successfully',
        fmt_support_logged_in: 'Barracuda Support logged in successfully',
        fmt_user_logged_out: 'User logged out successfully',
        fmt_user_logged_out_domain: 'User logged out of domain %s management successfully',
        fmt_support_logged_out: 'Barracuda Support logged out successfully',
        fmt_atd_exported: 'ATD log lines exported',
        fmt_message_log_exported: 'Message log lines exported',
        fmt_message_deleted: 'Message %s deleted',
        fmt_message_sent_to_emailreg: 'Message %s sent to EmailReg',
        fmt_message_blocked: 'Message %s blocked',
        fmt_message_whitelisted: 'Message %s whitelisted',
        fmt_message_recategorized: 'Message %s recategorized',
        fmt_message_delivered: 'Message %s delivered',
        fmt_message_viewed: 'Message %s viewed',
        fmt_message_marked_spam: 'Message %s marked as spam',
        fmt_message_marked_not_spam: 'Message %s marked as not spam',
        fmt_message_rejected: 'Message %s rejected',
        fmt_setting_created: '%s created',
        fmt_user_created: 'User %s created',
        fmt_outbound_ip_created: 'Outbound IP %s created',
        fmt_domain_created: 'Domain %s created',
        fmt_user_settings_deleted: 'All settings for user %s deleted',
        fmt_domain_users_deleted: 'All Users in domain %s deleted',
        fmt_account_outbound_ips_deleted: 'All Outbound IPs for domain %s in account %s deleted',
        fmt_list_outbound_ips_deleted: 'All Outbound IPs in list [%s] deleted',
        fmt_domain_settings_deleted: 'All settings for domain %s deleted',
        fmt_account_settings_deleted: 'All settings for account %s deleted',
        fmt_domain_settings_reset_account_level: 'Domain Settings for domain %s reset to account-level equivalents',
        fmt_login_info_sent: 'Login information has been sent to <strong>%s</strong>',
        fmt_account_ended: 'Account ended',
        fmt_account_disabled: 'Account disabled',
        fmt_account_suspended: 'Account suspended',
        fmt_account_migrated_to_cpl: 'Account migrated to CPL',
        fmt_user_notified_via_email: 'User(s) %s has been notified via email',
        fmt_domain_settings_restored: 'Domain Settings [%s] restored for domain %s',
        fmt_login_failure: 'Login Failure'
      }
    },
    atp_settings: {
      title: 'Advanced Threat Protection',
      enable_advanced_threat_protection: 'Enable Advanced Threat Protection',
      deliver_scan: 'Deliver First, then Scan',
      scan_deliver: 'Scan First, then Deliver',
      no: 'No',
      disabling_antivirus: 'Disabling Anti-Virus will disable Advanced Threat Protection',
      notify_admin: 'Notify Admin',
      yes: 'Yes',
      admin_emails: 'ATP Notification Email',
      atp_exemptions: 'ATP Exemptions',
      atp_exemptions_note: 'Note: Any entries listed here will not have attachments scanned with the ATP service.',
      exemptions_by_email_addresses_domains: 'Exemptions by Email Address / Domains',
      exemptions_by_email_addresses_domains_short: 'Exemptions (Email addresses / Domain)',
      exemptions_by_ip_addresses: 'Exemptions by Sender IP Address',
      exemptions_by_ip_addresses_short: 'Exemptions (Sender IP Address)',
      netmask: 'Netmask',
      anti_virus_disabled:
        'Advanced Threat Protection has been disabled because virus scanning has been disabled. <br></br> Please set Scan Email for Viruses to Yes on the Inbound Settings -> Anti-spam/Antivirus page.',
      error: {
        empty_email: 'Please enter a valid email address.',
        invalid_email: 'The email address {email} is not valid.',
        duplicate_email: '{email} is already exempted',
        empty_ip_address: 'Please enter a valid IP address.',
        invalid_ip_address: 'Invalid IP address',
        empty_netmask: 'Please enter a valid netmask.',
        invalid_netmask: 'Invalid netmask.',
        conflict_subnet: '{ip} is already exempted'
      }
    },
    account_management: {
      title: 'Syslog',
      header: 'Syslog integration',
      hostname: 'IP address/hostname',
      port: 'Port',
      helper_text: 'TCP+TLS is required to connect successfully. Non-TLS is not supported',
      add: 'Add',
      test: 'Test',
      delete: 'Delete',
      specify_hostname: 'Please specify a hostname.',
      specify_port: 'Please specify a port.',
      hostname_info: 'TCP+TLS is required to connect successfully. Non-TLS is not supported',
      error_missing_hostname: 'Error on row {row} (Invalid hostname)',
      error_invalid_hostname: 'Error on row {row} (Invalid hostname)',
      error_missing_port: 'Error on row {row} (Invalid port)',
      error_invalid_port: 'Error on row {row} (Invalid port)'
    },
    oauth2: {
      process: {
        start_failed: 'Failed to start the authentication process'
      },
      auth_code_flow: {
        start_failed: 'Failed to start the authorization code flow.',
        token_exchange_failed: 'Authorization code exchange failed.',
        create_session_failed: 'Authentication failed.'
      },
      exception: {
        OAuth2AuthorizeParameterMissing: 'A required parameter was not provided for the authorize call.',
        OAuth2AuthorizationCodeRequestFailed: 'Authorization code request failed.',
        OAuth2IdentityProviderFlowFailed: 'An error occurred while executing the authentication flow.',
        OAuth2TokenExchangePKCEVerificationFailed: 'The token exchange code verification failed.',
        IdTokenGenerationFailed: 'An error occurred while generating the ID token.',
        EGDIdentityNotFound: 'Sorry, that email address is not recognized as a valid user.',
        OAuth2AuthorizeRedirectURINotAllowed: 'The specified redirect URI is not allowed.',
        azure_ad_api_token_failed: 'Token exchange failed.',
        azure_ad_api_env_missing: 'An error occurred while trying to process the request.',
        ms_invalid_request: 'The authorization request was invalid.',
        ms_unauthorized_client: "The client application isn't permitted to request an authorization code.",
        ms_access_denied:
          'This app needs permission to access resources in your organization that only an admin can grant. Please ask an admin to grant permission to this app before you can use it.',
        ms_unsupported_response_type: "The authorization server doesn't support the response type in the request.",
        ms_server_error: 'The server encountered an unexpected error.',
        ms_temporarily_unavailable: 'The server is temporarily too busy to handle the request.',
        ms_invalid_resource:
          "The target resource is invalid because it doesn't exist, Microsoft Entra ID can't find it, or it's not correctly configured.",
        ms_login_required: 'Too many or no users found.',
        ms_interaction_required: 'The request requires user interaction.',
        ms_invalid_grant: 'The authorization code or PKCE code verifier is invalid or has expired.',
        ms_invalid_client: 'Client authentication failed.',
        ms_unsupported_grant_type: "The authorization server doesn't support the authorization grant type.",
        ms_consent_required: 'The request requires user consent.',
        ms_invalid_scope: 'The scope requested by the app is invalid.',
        tdf_invalid_domain: 'Sorry, that domain is not recognized as a valid domain.',
        tdf_domain_not_verified: 'Sorry, that domain is not verified.',
        azure_ad_api_error_token_failed: 'The authentication attempt was rejected.',
        OAuth2ProcessNotFound: 'The authentication request has expired. Please try signing in again.'
      }
    }
  }
}
/* eslint-enable quotes */
