import { Alert, Box, useTokens } from '@barracuda-internal/bds-core-v2'
import React, { useMemo, useState } from 'react'
import { useAppSelector } from 'redux/toolkit/hooks'
import { useErrorParam } from '../../login/useErrorParam'

const ErrorComponent: React.FC = () => {
  const tokens = useTokens()

  const [error, setError] = useState('')
  const [errorDescription, setErrorDescription] = useState('')

  useErrorParam({ stayOnRoute: true, setTopErrorMessage: setError, setTopErrorDescription: setErrorDescription })

  const { setupFlowError, newMxRecordsError, oldMxRecordsError } = useAppSelector(_store => ({
    setupFlowError: _store.emailServer.setupFlowError,
    newMxRecordsError: _store.emailServer.emailFlowState.newRecordsError,
    oldMxRecordsError: _store.emailServer.emailFlowState.oldRecordsError
  }))

  const errors = useMemo(() => {
    const displayError = errorDescription || error || null
    const possibleErrors = [displayError, setupFlowError, newMxRecordsError, oldMxRecordsError]
    return possibleErrors.filter((possibleError): possibleError is string => !!possibleError)
  }, [error, errorDescription, setupFlowError, newMxRecordsError, oldMxRecordsError])

  return useMemo(
    () => (
      <Box sx={{ paddingRight: tokens.aliasesSpaceContainerDefault, paddingLeft: tokens.aliasesSpaceContainerDefault }}>
        {errors &&
          errors.map(e => (
            <Alert bdsBoxShadowType="inline" bdsType="neutralError">
              {e}
            </Alert>
          ))}
      </Box>
    ),
    [tokens, errors]
  )
}

export default ErrorComponent
