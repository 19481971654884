import { useMemo, useCallback, ChangeEvent } from 'react'

import { useAppDispatch, useAppSelector } from 'redux/toolkit/hooks'
import { MicrosoftVerifiedDomain } from 'types/emailServer'
import { setMsDomainName } from 'redux/features/emailServer/emailServerSlice'

export interface State {
  msDomainName?: string
  verifiedDomains: MicrosoftVerifiedDomain[]
}

export interface EventHandlers {
  onSelectChange: (e: ChangeEvent<HTMLInputElement>) => void
}

export type UseVerifyMsDomainLogic = [State, EventHandlers]

export const useVerifyMsDomainLogic = (): UseVerifyMsDomainLogic => {
  const dispatch = useAppDispatch()

  const { msDomainName, verifiedDomains } = useAppSelector(_store => ({
    msDomainName: _store.emailServer.msDomainName,
    verifiedDomains: _store.emailServer.emailServerSettings?.microsoftVerifiedDomains
  }))

  const onSelectChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      dispatch(setMsDomainName(e.target.value))
    },
    [dispatch]
  )

  return useMemo(
    () => [
      {
        msDomainName,
        verifiedDomains: verifiedDomains || []
      },
      {
        onSelectChange
      }
    ],
    [msDomainName, verifiedDomains, onSelectChange]
  )
}
