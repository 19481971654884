import { useCallback, useMemo } from 'react'
import { useAppDispatch, useAppSelector } from 'redux/toolkit/hooks'
import { saveDeploymentMethod } from 'redux/features/emailServer/emailServerSlice'
import { INLINE_DEPLOYMENT_STATUSES } from 'types/emailServer'
import { DeploymentSetupOptions, SetupFlowSteps } from '../../../types/egdWizardTypes'

export interface State {
  error: boolean
}
export interface EventHandlers {
  onErrorClick: () => void
}

export type InlineEmailFlowLogic = [State, EventHandlers]

export const useInlineEmailFlowLogic = (): InlineEmailFlowLogic => {
  const dispatch = useAppDispatch()

  const { deploymentSetup, inlineDeploymentError, inlineDeploymentStatus } = useAppSelector(_store => ({
    deploymentSetup: _store.emailServer.deploymentSetup,
    inlineDeploymentError: _store.emailServer.inlineDeploymentError,
    inlineDeploymentStatus: _store.emailServer.inlineDeploymentStatus
  }))

  const onErrorClick = useCallback(
    () =>
      dispatch(
        saveDeploymentMethod({ step: SetupFlowSteps.emailFlow, deploymentMethod: DeploymentSetupOptions.mxRecord })
      ),
    [dispatch]
  )

  return useMemo(
    () => [
      {
        error: inlineDeploymentError || inlineDeploymentStatus === INLINE_DEPLOYMENT_STATUSES.FAILED
      },
      {
        onErrorClick
      }
    ],
    [inlineDeploymentError, inlineDeploymentStatus, onErrorClick]
  )
}
