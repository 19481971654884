const TITLE = 'Email Gateway Defense Setup'
const SUBTITLE = 'Defend your business from spam, malware and advanced email threats.'
const SPECIFY_EMAIL_SERVER = 'Specify email server'
const ADD_NEW_MX_RECORDS = 'Add new MX records'
const REMOVE_OLD_MX_RECORDS = 'Remove old MX records'
const MX_UPDATE_NOTE = 'Note: MX record updates can take up to 48 hours to take effect'
const PRIORITY = 'Priority'
const DOMAIN = 'Domain'
const STATUS = 'Status'
const BARRACUDA_CAMPUS_MX_RECORD_HELP = 'How to Set Up MX Records for Domain Verification | Barracuda Campus'
const PROVIDER_MX_LINKS = 'How to manage MX records | {providerName}'
const LINK_COPIED = 'Link copied to clipboard'

/* eslint-disable quotes */
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  ess_wizard: {
    email_protection: 'Email Protection',
    setup: 'Setup',
    exit: 'Exit',
    next: 'Next',
    complete_setup: 'Complete setup',
    verified: 'Verified',
    unverified: 'Unverified',
    introduction_step: 'Introduction',
    deploymentSetup_step: 'Deployment method',
    microsoftConnected_step: 'Microsoft connected',
    regionSelection_step: 'Set data region',
    verifyDomain_step: 'Verify domain',
    emailFlow_step: 'Set up email flow',
    skipped: 'Skipped',
    inline_deployment_loading: 'This process might take a while...',
    introduction: {
      title: 'Email Protection setup',
      subtitle: 'Get started with preventing attacks, securing emails and automating incident response'
    },
    microsoft_connected: {
      title: 'Connect to Microsoft',
      subtitle: 'Authenticate into your Microsoft account and grant permission to connect',
      content:
        'Barracuda requires permissions to your Microsoft environment to provide access to the following email protection features:',
      bullet_1: 'Prevent advanced threat types',
      bullet_2: 'Secure email after delivery',
      bullet_3: 'Automate email incident response',
      ms_365: 'Microsoft 365 Global Admin required',
      skip: 'Skip',
      connect: 'Connect',
      connected_title: 'You are connected!',
      connected_subtitle:
        'Barracuda AI is successfully connected to your account. Continue with the setup while we’re scanning.',
      connected_content_title: 'Preparing to protect your business from advanced email threats',
      connected_content:
        'Our AI is learning your organization’s communication patterns so that it can detect fraud. You will receive an email at youremail@domain.com when the scan is complete. Depending on the number of emails in your account, it may take a few hours or up to a day.',
      scan_complete: 'Scan complete',
      dialog: {
        title: 'Continue without Microsoft 365?',
        content_1:
          'Without permissions to your Microsoft 365 environment, Barracuda will not be able to provide access to the following advanced email protection features:',
        content_2:
          'You will still be able to use our basic gateway features including spam, malware, and ransomware protection.',
        bullet_1: 'AI-powered detection and response',
        bullet_2: 'Account takeover protection',
        bullet_3: 'DMARC reporting',
        okay: 'Okay'
      }
    },
    deployment_setup: {
      title: 'Email Security deployment method',
      subtitle: 'Select one option for how your emails will be routed through Barracuda',
      inline: 'Inline deployment',
      inline_title: 'Optional method',
      inline_subtitle:
        'Leave your DNS MX records unchanged and leverage Microsoft Exchange mail flow rules to route emails to Barracuda.',
      mx_record: 'MX record deployment',
      mx_record_title: 'Recommended',
      mx_record_subtitle: 'Update your DNS MX records to establish Barracuda as the initial gateway for your emails.',
      learn_more: 'Learn more'
    },
    region_selection: {
      title: TITLE,
      subtitle: SUBTITLE,
      selector_title: 'Select data center region',
      selector_subtitle:
        'Store your messages, logs and settings securely. Your data will comply with the privacy policies in the region you select.',
      selector_warning: 'Data center region cannot be changed after confirming',
      region: 'Region',
      confirm_region: 'Confirm region',
      missed_region_error: 'Must choose a region to continue'
    },
    email_flow: {
      title: 'Set up email flow',
      subtitle: 'Finish the steps needed to get your email flowing through Barracuda’s protective layer',
      inline_subtitle: 'You selected inline setup to get your email flowing through Barracuda’s protective layer.',
      inline_content: 'Click on <b>Next</b> to get your Microsoft account configured for inline deployment.',
      inline_deployment_error:
        'The process to enable inline deployment via Microsoft failed. Click on <b>Next</b> again or switch to <a>MX Record deployment</a>.',
      section_1_title: 'Add new MX records',
      section_1_subtitle:
        'To confirm domain ownership, add the Primary and Backup MX records below through your DNS Hosting Provider website. Setting priority 99 for the new MX records will ensure your current inbound email flow isn’t disrupted.',
      section_2_title: 'Remove old MX records',
      section_2_subtitle:
        'After the new MX records are verified in Step 1, you will need to remove your old MX records through your DNS hosting provider site so that all of your inbound email will be filtered and protected by Email Gateway Defense.',
      copy_to_clipboard: 'Copy to clipboard',
      copied_to_clipboard: LINK_COPIED,
      add_new_mx_records: {
        title: 'We detected the following information about your DNS Hosting Provider.',
        dns_hosting_provider: 'DNS Hosting Provider',
        link: 'Link',
        add_mx_record_link: BARRACUDA_CAMPUS_MX_RECORD_HELP,
        add_mx_record_custom_provider_link: PROVIDER_MX_LINKS,
        provider_website: '{provider} website',
        mx_record: 'MX Record',
        priority: PRIORITY,
        domain: DOMAIN,
        status: STATUS,
        primary: 'Primary',
        backup: 'Backup',
        mx_record_note: 'Note: MX record updates can take up to 48 hours to take effect',
        verify_records: 'Verify records',
        mx_update_note: MX_UPDATE_NOTE,
        mx_records_missed_to_verify_error: 'MX record needs to be verified to complete setup'
      },
      remove_old_mx_records: {
        subtitle: 'Note: Keep a copy of your old MX records before removing them.',
        edit_mx_record_link: BARRACUDA_CAMPUS_MX_RECORD_HELP,
        edit_mx_record_custom_provider_link: PROVIDER_MX_LINKS,
        priority: PRIORITY,
        domain: DOMAIN,
        status: STATUS,
        verify_update: 'Verify update',
        mx_update_note: MX_UPDATE_NOTE,
        old_mx_records_missed_to_verify_error: 'Removal of old MX records needs to be verified to complete setup',
        success_record_message: 'Old MX records successfully removed'
      }
    },
    verify_domain: {
      title: 'Verify your domain',
      subtitle: 'Please confirm the domain you would like Barracuda to protect.',
      email_address: 'Your domain email address',
      your_domain: 'Your domain',
      invalid_email_address: 'Enter valid email address from your domain',
      unable_to_detect_email_server_title: 'We weren’t able to detect an email server for your domain',
      unable_to_detect_email_server_subtitle_1:
        'Check the spelling of your email above or try a different email address',
      unable_to_detect_email_server_subtitle_2:
        'If your domain was recently created, it can take up to 48 hours to be discoverable',
      unable_to_detect_email_server_subtitle_3:
        'If you are sure the domain is correct, add Hostname/IP Address and Port manually',
      detect_email_server: 'Detect email server',
      missed_email_server: 'Email server needs to be detected',
      mail_server: 'Mail server',
      port: 'Port',
      verify_server_error: 'Mail server needs to be verified to complete setup',
      mail_server_name_error: 'Valid Hostname/IP address required',
      mail_server_port_error: 'Port required',
      action: 'Action',
      edit: 'Edit',
      add: 'Add',
      update: 'Update',
      status: 'Status',
      server_placeholder: 'Hostname/IP address',
      port_placeholder: 'Port',
      verify_server: 'Verify server',
      server_update_info: 'Note: Mail servers can take up to 48 hours to be discoverable for new domains.',
      verified: 'Verified',
      unverified: 'Unverified',
      domain: 'Domain'
    },
    server_and_mx_setup: {
      verified: 'Verified',
      unverified: 'Unverified',
      copy_to_clipboard: 'Copy to clipboard',
      copied_to_clipboard: LINK_COPIED,
      nav: {
        item_1: SPECIFY_EMAIL_SERVER,
        item_2: ADD_NEW_MX_RECORDS,
        item_3: REMOVE_OLD_MX_RECORDS
      },
      content: {
        title: TITLE,
        subtitle: SUBTITLE,
        section_1_title: SPECIFY_EMAIL_SERVER,
        section_1_subtitle:
          'To verify the email server you want to protect, enter a valid email address from that domain. More servers can be added in the product settings once this initial setup process is completed.',
        section_2_title: ADD_NEW_MX_RECORDS,
        section_2_subtitle:
          'To confirm domain ownership, add the Primary and Backup MX records below through your DNS Hosting Provider website. Setting priority 99 for the new MX records will ensure your current inbound email flow isn’t disrupted.',
        section_3_title: REMOVE_OLD_MX_RECORDS,
        section_3_subtitle:
          'After the new MX records are verified in Step 2, you will need to remove your old MX records through your DNS hosting provider site so that all of your inbound email will be filtered and protected by Email Gateway Defense.',
        save_and_exit: 'Save & exit',
        complete_setup: 'Complete setup',
        specify_email_server: {
          email_address: 'Your domain email address',
          invalid_email_address: 'Enter valid email address from your domain',
          unable_to_detect_email_server_title: 'We weren’t able to detect an email server for your domain',
          unable_to_detect_email_server_subtitle_1:
            'Check the spelling of your email above or try a different email address',
          unable_to_detect_email_server_subtitle_2:
            'If your domain was recently created, it can take up to 48 hours to be discoverable',
          unable_to_detect_email_server_subtitle_3:
            'If you are sure the domain is correct, add Hostname/IP Address and Port manually',
          detect_email_server: 'Detect email server',
          missed_email_server: 'Email server needs to be detected',
          mail_server: 'Mail server',
          port: 'Port',
          verify_server_error: 'Mail server needs to be verified to complete setup',
          mail_server_name_error: 'Valid Hostname/IP address required',
          mail_server_port_error: 'Port required',
          action: 'Action',
          edit: 'Edit',
          add: 'Add',
          update: 'Update',
          status: 'Status',
          server_placeholder: 'Hostname/IP address',
          port_placeholder: 'Port',
          verify_server: 'Verify server',
          server_update_info: 'Note: Mail servers can take up to 48 hours to be discoverable for new domains.'
        },
        add_new_mx_records: {
          title: 'We detected the following information about your DNS Hosting Provider.',
          dns_hosting_provider: 'DNS Hosting Provider',
          link: 'Link',
          add_mx_record_link: BARRACUDA_CAMPUS_MX_RECORD_HELP,
          add_mx_record_custom_provider_link: PROVIDER_MX_LINKS,
          provider_website: '{provider} website',
          mx_record: 'MX Record',
          priority: PRIORITY,
          domain: DOMAIN,
          status: STATUS,
          primary: 'Primary',
          backup: 'Backup',
          mx_record_note: 'Note: MX record updates can take up to 48 hours to take effect',
          verify_records: 'Verify records',
          mx_update_note: MX_UPDATE_NOTE,
          mx_records_missed_to_verify_error: 'MX record needs to be verified to complete setup'
        },
        remove_old_mx_records: {
          subtitle: 'Note: Keep a copy of your old MX records before removing them.',
          edit_mx_record_link: BARRACUDA_CAMPUS_MX_RECORD_HELP,
          edit_mx_record_custom_provider_link: PROVIDER_MX_LINKS,
          priority: PRIORITY,
          domain: DOMAIN,
          status: STATUS,
          verify_update: 'Verify update',
          mx_update_note: MX_UPDATE_NOTE,
          old_mx_records_missed_to_verify_error: 'Removal of old MX records needs to be verified to complete setup',
          success_record_message: 'Old MX records successfully removed'
        }
      }
    },
    completed_setup: {
      title: 'You’re set up!',
      subtitle: 'Congratulations, your Email Protection is ready to use.',
      content: 'Click on <b>Complete setup</b> to enjoy your new Email Protection capabilities.'
    },
    error_page: {
      title: 'Setup cannot be loaded',
      subtitle: 'Data for the setup flow cannot be loaded, please try again later.',
      migration_error_title: 'Migration error',
      migration_error_subtitle: 'Unable to migrate account data to the new region, please try again later.'
    }
  }
}
/* eslint-enable quotes */
