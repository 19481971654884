import { ChangeEvent, useCallback, useMemo } from 'react'
import { useAppDispatch, useAppSelector } from 'redux/toolkit/hooks'
import { setDeploymentSetup } from 'redux/features/emailServer/emailServerSlice'
import { isMyFeatureOn } from 'lib/splitio'
import { FEATURES } from 'lib/splitioFeatures'
import { DeploymentSetupOptions } from '../../types/egdWizardTypes'

export interface State {
  deploymentSetup: DeploymentSetupOptions
  inlineDisabled: boolean
}

export interface EventHandlers {
  onSelectChange: (e: ChangeEvent<HTMLInputElement>) => void
}

export type DeploymentSetupLogic = [State, EventHandlers]

export const useDeploymentSetupLogic = (): DeploymentSetupLogic => {
  const dispatch = useAppDispatch()
  const isInlineDeploymentRollout = isMyFeatureOn(FEATURES.EGD_React_Inline_Deployment_Rollout)

  const { deploymentSetup, emailServerSettings } = useAppSelector(_store => ({
    deploymentSetup: _store.emailServer.deploymentSetup,
    emailServerSettings: _store.emailServer.emailServerSettings
  }))

  const onSelectChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      dispatch(setDeploymentSetup(e.target.value as DeploymentSetupOptions))
    },
    [dispatch]
  )

  return useMemo(
    () => [
      {
        deploymentSetup,
        inlineDisabled:
          !emailServerSettings?.ipirAccountId || !emailServerSettings?.ipirTokenId || !isInlineDeploymentRollout
      },
      {
        onSelectChange
      }
    ],
    [deploymentSetup, emailServerSettings, isInlineDeploymentRollout, onSelectChange]
  )
}
