import React, { useMemo } from 'react'
import { Avatar, Box, Button, Chip, LinearProgress, Typography, useTokens } from '@barracuda-internal/bds-core-v2'
import { useFormatMessage } from 'lib/localization'
import SetupFlowStepsContentContainer from '../../layout/SetupFlowStepsContentContainer'
import ContentTitleComponent from '../../components/ContentTitleComponent'
import ErrorComponent from '../../components/ErrorComponent'
import { useMicrosoftConnectedLogic } from './useMicrosoftConnectedLogic'
import ContinueDialog from './ContinueDialog'

const BASE_I18N_KEY = 'ess_wizard.microsoft_connected'

const MicrosoftConnected: React.FC = () => {
  const tokens = useTokens()
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const [state, eventHandlers] = useMicrosoftConnectedLogic()

  return useMemo(
    () => (
      <Box sx={{ height: '100%' }}>
        {state.skipDialogOpen && <ContinueDialog onClose={eventHandlers.onClose} />}
        {state.connected && (
          <SetupFlowStepsContentContainer>
            <ContentTitleComponent
              title={formatMessage('connected_title')}
              subtitle={formatMessage('connected_subtitle')}
            />
            <ErrorComponent />
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                height: '64px',
                borderRadius: tokens.aliasesBorderRadiusDefault,
                border: '1px',
                borderColor: tokens.aliasesColorBorderStaticContainerSubtle,
                backgroundColor: tokens.aliasesColorBgStaticComponentNeutral,
                margin: tokens.aliasesSpaceContainerDefault,
                padding: tokens.aliasesSpaceContainerDefault
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: tokens.aliasesSpaceComponentDefault,
                  width: '274px'
                }}
              >
                <Avatar bdsType="staticSubtle" bdsSize="xxlarge">
                  {' '}
                  {state.initials}
                </Avatar>
                <Typography
                  sx={{
                    fontFamily: tokens.aliasesFontFamilyDefault,
                    fontSize: tokens.aliasesFontSizeDefault,
                    fontWeight: tokens.aliasesFontWeightEmphasis,
                    color: tokens.aliasesColorTextStaticEmphasis,
                    lineHeight: tokens.aliasesLineHeightDefault,
                    letterSpacing: tokens.aliasesLetterSpacingDefault
                  }}
                >
                  {state.userName}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  flexGrow: '1'
                }}
              >
                {!state.ipirScanStatus && <LinearProgress sx={{ maxWidth: 300 }} />}
                {state.ipirScanStatus && (
                  <Chip
                    bdsSize="medium"
                    bdsType="successNeutral"
                    label={formatMessage('scan_complete')}
                    sx={{
                      maxWidth: 300
                    }}
                  />
                )}
              </Box>
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                paddingRight: tokens.aliasesSpaceContainerDefault,
                paddingLeft: tokens.aliasesSpaceContainerDefault,
                gap: tokens.aliasesSpaceComponentDefault
              }}
            >
              <Typography
                sx={{
                  fontFamily: tokens.aliasesFontFamilyDefault,
                  fontSize: tokens.aliasesFontSizeDefault,
                  fontWeight: tokens.aliasesFontWeightNeutral,
                  color: tokens.aliasesColorTextStaticEmphasis,
                  lineHeight: tokens.aliasesLineHeightDefault
                }}
              >
                {formatMessage('connected_content_title')}
              </Typography>
              <Typography
                sx={{
                  fontFamily: tokens.aliasesFontFamilyDefault,
                  fontSize: tokens.aliasesFontSizeDefault,
                  fontWeight: tokens.aliasesFontWeightDefault,
                  color: tokens.aliasesColorTextStaticEmphasis,
                  lineHeight: tokens.aliasesLineHeightDefault,
                  letterSpacing: tokens.aliasesLetterSpacingDefault
                }}
              >
                {formatMessage('connected_content')}
              </Typography>
            </Box>
          </SetupFlowStepsContentContainer>
        )}
        {!state.connected && (
          <SetupFlowStepsContentContainer>
            <ContentTitleComponent title={formatMessage('title')} subtitle={formatMessage('subtitle')} />
            <ErrorComponent />
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                paddingRight: tokens.aliasesSpaceContainerDefault,
                paddingLeft: tokens.aliasesSpaceContainerDefault,
                gap: tokens.aliasesSpaceContainerDefault
              }}
            >
              <Typography
                sx={{
                  fontFamily: tokens.aliasesFontFamilyDefault,
                  fontSize: tokens.aliasesFontSizeDefault,
                  fontWeight: tokens.aliasesFontWeightDefault,
                  color: tokens.aliasesColorTextStaticEmphasis,
                  lineHeight: tokens.aliasesLineHeightDefault
                }}
              >
                {formatMessage('content')}
              </Typography>
              <Box>
                <Typography
                  sx={{
                    fontFamily: tokens.aliasesFontFamilyDefault,
                    fontSize: tokens.aliasesFontSizeDefault,
                    fontWeight: tokens.aliasesFontWeightDefault,
                    color: tokens.aliasesColorTextStaticEmphasis,
                    lineHeight: tokens.aliasesLineHeightDefault
                  }}
                >
                  {`\u2022 ${formatMessage('bullet_1')}`}
                </Typography>
                <Typography
                  sx={{
                    fontFamily: tokens.aliasesFontFamilyDefault,
                    fontSize: tokens.aliasesFontSizeDefault,
                    fontWeight: tokens.aliasesFontWeightDefault,
                    color: tokens.aliasesColorTextStaticEmphasis,
                    lineHeight: tokens.aliasesLineHeightDefault
                  }}
                >
                  {`\u2022 ${formatMessage('bullet_2')}`}
                </Typography>
                <Typography
                  sx={{
                    fontFamily: tokens.aliasesFontFamilyDefault,
                    fontSize: tokens.aliasesFontSizeDefault,
                    fontWeight: tokens.aliasesFontWeightDefault,
                    color: tokens.aliasesColorTextStaticEmphasis,
                    lineHeight: tokens.aliasesLineHeightDefault
                  }}
                >
                  {`\u2022 ${formatMessage('bullet_3')}`}
                </Typography>
              </Box>
              <Typography
                sx={{
                  fontFamily: tokens.aliasesFontFamilyDefault,
                  fontSize: tokens.aliasesFontSizeDefault,
                  fontWeight: tokens.aliasesFontWeightNeutral,
                  color: tokens.aliasesColorTextStaticEmphasis,
                  lineHeight: tokens.aliasesLineHeightDefault,
                  display: 'flex',
                  alignItems: 'center',
                  gap: tokens.aliasesSpaceContainerDense
                }}
              >
                <img alt="" style={{ marginLeft: 0 }} src="/images/microsoft_logo_icon.svg" /> {formatMessage('ms_365')}
              </Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                gap: tokens.aliasesSpaceComponentDefault
              }}
            >
              <Button bdsType="interactiveSubtle" data-testid="no-microsoft" onClick={eventHandlers.onSkip}>
                {formatMessage('skip')}
              </Button>
              <Button bdsType="interactiveEmphasis" data-testid="connect" onClick={eventHandlers.onConnect}>
                {formatMessage('connect')}
              </Button>
            </Box>
          </SetupFlowStepsContentContainer>
        )}
      </Box>
    ),
    [formatMessage, tokens, state, eventHandlers]
  )
}

export default MicrosoftConnected
