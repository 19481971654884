export enum SetupFlowSteps {
  introduction = 'introduction',
  microsoftConnected = 'microsoftConnected',
  regionSelection = 'regionSelection',
  verifyDomain = 'verifyDomain',
  deploymentSetup = 'deploymentSetup',
  emailFlow = 'emailFlow',
  setupCompleted = 'setupCompleted'
}

export enum DeploymentSetupOptions {
  inline = 'inline',
  mxRecord = 'mx'
}

export enum EmailFlowSteps {
  addNewMxRecords = 'addNewMxRecords',
  removeOldMxRecords = 'removeOldMxRecords'
}

export type EmailFlowVerifiedStates = {
  [key in EmailFlowSteps]: boolean
}

export enum IPIRAppTypes {
  InlineMx = 'InlineMx',
  Sentinel = 'Sentinel'
}
