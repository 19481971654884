import React, { useMemo } from 'react'
import { Box, Typography, useTokens } from '@barracuda-internal/bds-core-v2'
import { useFormatMessage } from 'lib/localization'
import SetupFlowStepsContentContainer from '../../layout/SetupFlowStepsContentContainer'
import ContentTitleComponent from '../../components/ContentTitleComponent'

const BASE_I18N_KEY = 'ess_wizard.completed_setup'

const SetupCompleted: React.FC = () => {
  const tokens = useTokens()
  const formatMessage = useFormatMessage(BASE_I18N_KEY)

  return useMemo(
    () => (
      <SetupFlowStepsContentContainer>
        <ContentTitleComponent title={formatMessage('title')} subtitle={formatMessage('subtitle')} />
        <Typography
          sx={{
            fontFamily: tokens.aliasesFontFamilyDefault,
            fontSize: tokens.aliasesFontSizeDefault,
            fontWeight: tokens.aliasesFontWeightDefault,
            color: tokens.aliasesColorTextStaticEmphasis,
            lineHeight: tokens.aliasesLineHeightDefault,
            padding: tokens.aliasesSpaceContainerDefault
          }}
        >
          {formatMessage('content')}
        </Typography>
        <Box sx={{ width: 300, padding: tokens.aliasesSpaceContainerDefault }}>
          <img alt="" src="/images/setup_completed.svg" />
        </Box>
      </SetupFlowStepsContentContainer>
    ),
    [formatMessage, tokens]
  )
}

export default SetupCompleted
